"use strict";
exports.__esModule = true;
exports.DSIconsLineConfig = void 0;
var Unicode = {
    Abacus: '\f101',
    AccessibleIconAlt: '\f102',
    Adjust: '\f106',
    AdjustAlt: '\f103',
    AdjustCircle: '\f104',
    AdjustHalf: '\f105',
    Adobe: '\f108',
    AdobeAlt: '\f107',
    Airplay: '\f109',
    Align: '\f116',
    AlignAlt: '\f10a',
    AlignCenter: '\f10f',
    AlignCenterAlt: '\f10b',
    AlignCenterH: '\f10c',
    AlignCenterJustify: '\f10d',
    AlignCenterV: '\f10e',
    AlignJustify: '\f110',
    AlignLeft: '\f112',
    AlignLeftJustify: '\f111',
    AlignLetterRight: '\f113',
    AlignRight: '\f115',
    AlignRightJustify: '\f114',
    Amazon: '\f117',
    Ambulance: '\f118',
    AmericanExpress: '\f119',
    Analysis: '\f11a',
    Analytics: '\f11b',
    Anchor: '\f11c',
    Android: '\f11f',
    AndroidAlt: '\f11d',
    AndroidPhoneSlash: '\f11e',
    AngleDoubleDown: '\f120',
    AngleDoubleLeft: '\f121',
    AngleDoubleRight: '\f122',
    AngleDoubleUp: '\f123',
    AngleDown: '\f124',
    AngleLeft: '\f126',
    AngleLeftB: '\f125',
    AngleRight: '\f128',
    AngleRightB: '\f127',
    AngleUp: '\f129',
    Angry: '\f12a',
    Ankh: '\f12b',
    Annoyed: '\f12d',
    AnnoyedAlt: '\f12c',
    Apple: '\f12f',
    AppleAlt: '\f12e',
    Apps: '\f130',
    Archive: '\f132',
    ArchiveAlt: '\f131',
    Archway: '\f133',
    Arrow: '\f149',
    ArrowBreak: '\f134',
    ArrowCircleDown: '\f135',
    ArrowCircleLeft: '\f136',
    ArrowCircleRight: '\f137',
    ArrowCircleUp: '\f138',
    ArrowCompressH: '\f139',
    ArrowDown: '\f13c',
    ArrowDownLeft: '\f13a',
    ArrowDownRight: '\f13b',
    ArrowFromRight: '\f13d',
    ArrowFromTop: '\f13e',
    ArrowGrowth: '\f13f',
    ArrowLeft: '\f140',
    ArrowRandom: '\f141',
    ArrowResizeDiagonal: '\f142',
    ArrowRight: '\f143',
    ArrowToBottom: '\f144',
    ArrowToRight: '\f145',
    ArrowUp: '\f148',
    ArrowUpLeft: '\f146',
    ArrowUpRight: '\f147',
    ArrowsH: '\f14b',
    ArrowsHAlt: '\f14a',
    ArrowsLeftDown: '\f14c',
    ArrowsMaximize: '\f14d',
    ArrowsMerge: '\f14e',
    ArrowsResize: '\f151',
    ArrowsResizeH: '\f14f',
    ArrowsResizeV: '\f150',
    ArrowsRightDown: '\f152',
    ArrowsShrinkH: '\f153',
    ArrowsShrinkV: '\f154',
    ArrowsUpRight: '\f155',
    ArrowsV: '\f157',
    ArrowsVAlt: '\f156',
    AssistiveListeningSystems: '\f158',
    Asterisk: '\f159',
    At: '\f15a',
    Atom: '\f15b',
    AutoFlash: '\f15c',
    Award: '\f15e',
    AwardAlt: '\f15d',
    BabyCarriage: '\f15f',
    Backpack: '\f160',
    Backspace: '\f161',
    Backward: '\f162',
    Bag: '\f165',
    BagAlt: '\f163',
    BagSlash: '\f164',
    BalanceScale: '\f166',
    Ban: '\f167',
    BandAid: '\f168',
    Bars: '\f169',
    BaseballBall: '\f16a',
    Basketball: '\f16c',
    BasketballHoop: '\f16b',
    Bath: '\f16d',
    BatteryBolt: '\f16e',
    BatteryEmpty: '\f16f',
    Bed: '\f171',
    BedDouble: '\f170',
    Behance: '\f173',
    BehanceAlt: '\f172',
    Bell: '\f176',
    BellSchool: '\f174',
    BellSlash: '\f175',
    Bill: '\f177',
    Bing: '\f178',
    Bitcoin: '\f17c',
    BitcoinAlt: '\f179',
    BitcoinCircle: '\f17a',
    BitcoinSign: '\f17b',
    BlackBerry: '\f17d',
    Blogger: '\f17f',
    BloggerAlt: '\f17e',
    BluetoothB: '\f180',
    Bold: '\f181',
    Boleto: '\f182',
    Bolt: '\f185',
    BoltAlt: '\f183',
    BoltSlash: '\f184',
    Book: '\f18a',
    BookAlt: '\f186',
    BookMedical: '\f187',
    BookOpen: '\f188',
    BookReader: '\f189',
    Bookmark: '\f18c',
    BookmarkFull: '\f18b',
    Books: '\f18d',
    Boombox: '\f18e',
    BorderAlt: '\f18f',
    BorderBottom: '\f190',
    BorderClear: '\f191',
    BorderHorizontal: '\f192',
    BorderInner: '\f193',
    BorderLeft: '\f194',
    BorderOut: '\f195',
    BorderRight: '\f196',
    BorderTop: '\f197',
    BorderVertical: '\f198',
    BowlingBall: '\f199',
    Box: '\f19a',
    BracketsCurly: '\f19b',
    Brain: '\f19c',
    Briefcase: '\f19e',
    BriefcaseAlt: '\f19d',
    Bright: '\f19f',
    Brightness: '\f1a5',
    BrightnessEmpty: '\f1a0',
    BrightnessHalf: '\f1a1',
    BrightnessLow: '\f1a2',
    BrightnessMinus: '\f1a3',
    BrightnessPlus: '\f1a4',
    BringBottom: '\f1a6',
    BringFront: '\f1a7',
    Browser: '\f1a8',
    BrushAlt: '\f1a9',
    Bug: '\f1aa',
    Building: '\f1ab',
    Bullseye: '\f1ac',
    Bus: '\f1af',
    BusAlt: '\f1ad',
    BusSchool: '\f1ae',
    Calculator: '\f1b1',
    CalculatorAlt: '\f1b0',
    CalendarAlt: '\f1b2',
    CalendarSlash: '\f1b3',
    Calender: '\f1b4',
    Calling: '\f1b5',
    Camera: '\f1b9',
    CameraChange: '\f1b6',
    CameraPlus: '\f1b7',
    CameraSlash: '\f1b8',
    Cancel: '\f1ba',
    Capsule: '\f1bb',
    Capture: '\f1bc',
    Car: '\f1c0',
    CarSideview: '\f1bd',
    CarSlash: '\f1be',
    CarWash: '\f1bf',
    CardAtm: '\f1c1',
    CaretRight: '\f1c2',
    Cell: '\f1c3',
    Celsius: '\f1c4',
    Channel: '\f1c6',
    ChannelAdd: '\f1c5',
    Chart: '\f1cf',
    ChartBar: '\f1c8',
    ChartBarAlt: '\f1c7',
    ChartDown: '\f1c9',
    ChartGrowth: '\f1cb',
    ChartGrowthAlt: '\f1ca',
    ChartLine: '\f1cc',
    ChartPie: '\f1ce',
    ChartPieAlt: '\f1cd',
    Chat: '\f1d2',
    ChatBubbleUser: '\f1d0',
    ChatInfo: '\f1d1',
    Check: '\f1d5',
    CheckCircle: '\f1d3',
    CheckSquare: '\f1d4',
    Circle: '\f1d7',
    CircleLayer: '\f1d6',
    Circuit: '\f1d8',
    ClapperBoard: '\f1d9',
    ClinicMedical: '\f1da',
    Clipboard: '\f1de',
    ClipboardAlt: '\f1db',
    ClipboardBlank: '\f1dc',
    ClipboardNotes: '\f1dd',
    Clock: '\f1e6',
    ClockEight: '\f1df',
    ClockFive: '\f1e0',
    ClockNine: '\f1e1',
    ClockSeven: '\f1e2',
    ClockTen: '\f1e3',
    ClockThree: '\f1e4',
    ClockTwo: '\f1e5',
    ClosedCaptioning: '\f1e8',
    ClosedCaptioningSlash: '\f1e7',
    Cloud: '\f211',
    CloudBlock: '\f1e9',
    CloudBookmark: '\f1ea',
    CloudCheck: '\f1eb',
    CloudComputing: '\f1ec',
    CloudDataConnection: '\f1ed',
    CloudDatabaseTree: '\f1ee',
    CloudDownload: '\f1ef',
    CloudDrizzle: '\f1f0',
    CloudExclamation: '\f1f1',
    CloudHail: '\f1f2',
    CloudHeart: '\f1f3',
    CloudInfo: '\f1f4',
    CloudLock: '\f1f5',
    CloudMeatball: '\f1f6',
    CloudMoon: '\f1fb',
    CloudMoonHail: '\f1f7',
    CloudMoonMeatball: '\f1f8',
    CloudMoonRain: '\f1f9',
    CloudMoonShowers: '\f1fa',
    CloudQuestion: '\f1fc',
    CloudRain: '\f1fe',
    CloudRainSun: '\f1fd',
    CloudRedo: '\f1ff',
    CloudShare: '\f200',
    CloudShield: '\f201',
    CloudShowers: '\f204',
    CloudShowersAlt: '\f202',
    CloudShowersHeavy: '\f203',
    CloudSlash: '\f205',
    CloudSun: '\f20b',
    CloudSunHail: '\f206',
    CloudSunMeatball: '\f207',
    CloudSunRain: '\f209',
    CloudSunRainAlt: '\f208',
    CloudSunTear: '\f20a',
    CloudTimes: '\f20c',
    CloudUnlock: '\f20d',
    CloudUpload: '\f20e',
    CloudWifi: '\f20f',
    CloudWind: '\f210',
    Clouds: '\f212',
    Club: '\f213',
    Clubz: '\f214',
    CodeBranch: '\f215',
    Coffee: '\f216',
    Cog: '\f217',
    Coins: '\f218',
    Columns: '\f219',
    Comment: '\f24d',
    CommentAdd: '\f21a',
    CommentAlt: '\f233',
    CommentAltBlock: '\f21b',
    CommentAltChartLines: '\f21c',
    CommentAltCheck: '\f21d',
    CommentAltDots: '\f21e',
    CommentAltDownload: '\f21f',
    CommentAltEdit: '\f220',
    CommentAltExclamation: '\f221',
    CommentAltHeart: '\f222',
    CommentAltImage: '\f223',
    CommentAltInfo: '\f224',
    CommentAltLines: '\f225',
    CommentAltLock: '\f226',
    CommentAltMedical: '\f227',
    CommentAltMessage: '\f228',
    CommentAltNotes: '\f229',
    CommentAltPlus: '\f22a',
    CommentAltQuestion: '\f22b',
    CommentAltRedo: '\f22c',
    CommentAltSearch: '\f22d',
    CommentAltShare: '\f22e',
    CommentAltShield: '\f22f',
    CommentAltSlash: '\f230',
    CommentAltUpload: '\f231',
    CommentAltVerify: '\f232',
    CommentBlock: '\f234',
    CommentChartLine: '\f235',
    CommentCheck: '\f236',
    CommentDots: '\f237',
    CommentDownload: '\f238',
    CommentEdit: '\f239',
    CommentExclamation: '\f23a',
    CommentHeart: '\f23b',
    CommentImage: '\f23c',
    CommentInfo: '\f23e',
    CommentInfoAlt: '\f23d',
    CommentLines: '\f23f',
    CommentLock: '\f240',
    CommentMedical: '\f241',
    CommentMessage: '\f242',
    CommentNotes: '\f243',
    CommentPlus: '\f244',
    CommentQuestion: '\f245',
    CommentRedo: '\f246',
    CommentSearch: '\f247',
    CommentShare: '\f248',
    CommentShield: '\f249',
    CommentSlash: '\f24a',
    CommentUpload: '\f24b',
    CommentVerify: '\f24c',
    Comments: '\f24f',
    CommentsAlt: '\f24e',
    CompactDisc: '\f250',
    Comparison: '\f251',
    Compass: '\f252',
    Compress: '\f259',
    CompressAlt: '\f254',
    CompressAltLeft: '\f253',
    CompressArrows: '\f255',
    CompressLines: '\f256',
    CompressPoint: '\f257',
    CompressV: '\f258',
    Confused: '\f25a',
    Constructor: '\f25b',
    Copy: '\f25e',
    CopyAlt: '\f25c',
    CopyLandscape: '\f25d',
    Copyright: '\f25f',
    CornerDownLeft: '\f260',
    CornerDownRight: '\f262',
    CornerDownRightAlt: '\f261',
    CornerLeftDown: '\f263',
    CornerRightDown: '\f264',
    CornerUpLeft: '\f266',
    CornerUpLeftAlt: '\f265',
    CornerUpRight: '\f268',
    CornerUpRightAlt: '\f267',
    Coronavirus: '\f269',
    CreateDashboard: '\f26a',
    CreativeCommonsPd: '\f26b',
    CreditCard: '\f26d',
    CreditCardSearch: '\f26c',
    Crockery: '\f26e',
    CropAlt: '\f271',
    CropAltRotateLeft: '\f26f',
    CropAltRotateRight: '\f270',
    Crosshair: '\f273',
    CrosshairAlt: '\f272',
    Crosshairs: '\f274',
    Css3Simple: '\f275',
    Cube: '\f276',
    Dashboard: '\f277',
    DataSharing: '\f278',
    Database: '\f27a',
    DatabaseAlt: '\f279',
    Desert: '\f27b',
    Desktop: '\f280',
    DesktopAlt: '\f27d',
    DesktopAltSlash: '\f27c',
    DesktopCloudAlt: '\f27e',
    DesktopSlash: '\f27f',
    Dialpad: '\f282',
    DialpadAlt: '\f281',
    Diamond: '\f283',
    Diary: '\f285',
    DiaryAlt: '\f284',
    DiceFive: '\f286',
    DiceFour: '\f287',
    DiceOne: '\f288',
    DiceSix: '\f289',
    DiceThree: '\f28a',
    DiceTwo: '\f28b',
    DinersClub: '\f28c',
    Direction: '\f28d',
    Directions: '\f28e',
    Discord: '\f28f',
    Dislike: '\f290',
    DizzyMeh: '\f291',
    Dna: '\f292',
    Docker: '\f293',
    DocumentInfo: '\f294',
    DocumentLayoutCenter: '\f295',
    DocumentLayoutLeft: '\f296',
    DocumentLayoutRight: '\f297',
    DollarAlt: '\f298',
    DollarSign: '\f29a',
    DollarSignAlt: '\f299',
    DownloadAlt: '\f29b',
    Draggabledots: '\f29c',
    Dribbble: '\f29d',
    Drill: '\f29e',
    Dropbox: '\f29f',
    Dumbbell: '\f2a0',
    Ear: '\f2a1',
    Edit: '\f2a3',
    EditAlt: '\f2a2',
    EighteenPlus: '\f2a4',
    ElipsisDoubleVAlt: '\f2a5',
    EllipsisH: '\f2a6',
    EllipsisV: '\f2a7',
    Elo: '\f2a8',
    Emoji: '\f2a9',
    EnglishToChinese: '\f2aa',
    Enter: '\f2ab',
    Envelope: '\f2c5',
    EnvelopeAdd: '\f2ac',
    EnvelopeAlt: '\f2ad',
    EnvelopeBlock: '\f2ae',
    EnvelopeBookmark: '\f2af',
    EnvelopeCheck: '\f2b0',
    EnvelopeDownload: '\f2b2',
    EnvelopeDownloadAlt: '\f2b1',
    EnvelopeEdit: '\f2b3',
    EnvelopeExclamation: '\f2b4',
    EnvelopeHeart: '\f2b5',
    EnvelopeInfo: '\f2b6',
    EnvelopeLock: '\f2b7',
    EnvelopeMinus: '\f2b8',
    EnvelopeOpen: '\f2b9',
    EnvelopeQuestion: '\f2ba',
    EnvelopeReceive: '\f2bb',
    EnvelopeRedo: '\f2bc',
    EnvelopeSearch: '\f2bd',
    EnvelopeSend: '\f2be',
    EnvelopeShare: '\f2bf',
    EnvelopeShield: '\f2c0',
    EnvelopeStar: '\f2c1',
    EnvelopeTimes: '\f2c2',
    EnvelopeUpload: '\f2c4',
    EnvelopeUploadAlt: '\f2c3',
    Envelopes: '\f2c6',
    EqualCircle: '\f2c7',
    Estate: '\f2c8',
    Euro: '\f2ca',
    EuroCircle: '\f2c9',
    Exchange: '\f2cc',
    ExchangeAlt: '\f2cb',
    Exclamation: '\f2d0',
    ExclamationCircle: '\f2cd',
    ExclamationOctagon: '\f2ce',
    ExclamationTriangle: '\f2cf',
    Exclude: '\f2d1',
    Exit: '\f2d2',
    ExpandAlt: '\f2d3',
    ExpandArrows: '\f2d5',
    ExpandArrowsAlt: '\f2d4',
    ExpandFromCorner: '\f2d6',
    ExpandLeft: '\f2d7',
    ExpandRight: '\f2d8',
    Export: '\f2d9',
    ExposureAlt: '\f2da',
    ExposureIncrease: '\f2db',
    ExternalLinkAlt: '\f2dc',
    Eye: '\f2de',
    EyeSlash: '\f2dd',
    Facebook: '\f2e2',
    FacebookF: '\f2df',
    FacebookMessenger: '\f2e1',
    FacebookMessengerAlt: '\f2e0',
    Fahrenheit: '\f2e3',
    FastMail: '\f2e5',
    FastMailAlt: '\f2e4',
    Favorite: '\f2e6',
    Feedback: '\f2e7',
    FidgetSpinner: '\f2e8',
    File: '\f313',
    FileAlt: '\f2e9',
    FileBlank: '\f2ea',
    FileBlockAlt: '\f2eb',
    FileBookmarkAlt: '\f2ec',
    FileCheck: '\f2ee',
    FileCheckAlt: '\f2ed',
    FileContract: '\f2f0',
    FileContractDollar: '\f2ef',
    FileCopyAlt: '\f2f1',
    FileDownload: '\f2f3',
    FileDownloadAlt: '\f2f2',
    FileEditAlt: '\f2f4',
    FileExclamation: '\f2f6',
    FileExclamationAlt: '\f2f5',
    FileExport: '\f2f7',
    FileGraph: '\f2f8',
    FileHeart: '\f2f9',
    FileImport: '\f2fa',
    FileInfoAlt: '\f2fb',
    FileLandscape: '\f2fd',
    FileLandscapeAlt: '\f2fc',
    FileLanscapeSlash: '\f2fe',
    FileLockAlt: '\f2ff',
    FileMedical: '\f301',
    FileMedicalAlt: '\f300',
    FileMinus: '\f303',
    FileMinusAlt: '\f302',
    FileNetwork: '\f304',
    FilePlus: '\f306',
    FilePlusAlt: '\f305',
    FileQuestion: '\f308',
    FileQuestionAlt: '\f307',
    FileRedoAlt: '\f309',
    FileSearch: '\f30b',
    FileSearchAlt: '\f30a',
    FileShareAlt: '\f30c',
    FileShieldAlt: '\f30d',
    FileSlash: '\f30e',
    FileTimes: '\f310',
    FileTimesAlt: '\f30f',
    FileUpload: '\f312',
    FileUploadAlt: '\f311',
    FilesLandscapes: '\f315',
    FilesLandscapesAlt: '\f314',
    Film: '\f316',
    Filter: '\f318',
    FilterSlash: '\f317',
    Fire: '\f319',
    FivehundredPx: '\f31a',
    Flask: '\f31c',
    FlaskPotion: '\f31b',
    FlipH: '\f31e',
    FlipHAlt: '\f31d',
    FlipV: '\f320',
    FlipVAlt: '\f31f',
    Flower: '\f321',
    Focus: '\f324',
    FocusAdd: '\f322',
    FocusTarget: '\f323',
    Folder: '\f334',
    FolderCheck: '\f325',
    FolderDownload: '\f326',
    FolderExclamation: '\f327',
    FolderHeart: '\f328',
    FolderInfo: '\f329',
    FolderLock: '\f32a',
    FolderMedical: '\f32b',
    FolderMinus: '\f32c',
    FolderNetwork: '\f32d',
    FolderOpen: '\f32e',
    FolderPlus: '\f32f',
    FolderQuestion: '\f330',
    FolderSlash: '\f331',
    FolderTimes: '\f332',
    FolderUpload: '\f333',
    Font: '\f335',
    Football: '\f338',
    FootballAmerican: '\f336',
    FootballBall: '\f337',
    ForecastcloudMoonTear: '\f339',
    ForwadedCall: '\f33a',
    Forward: '\f33b',
    Frown: '\f33c',
    GameStructure: '\f33d',
    Gift: '\f33e',
    Github: '\f340',
    GithubAlt: '\f33f',
    Gitlab: '\f341',
    Glass: '\f346',
    GlassMartini: '\f344',
    GlassMartiniAlt: '\f343',
    GlassMartiniAltSlash: '\f342',
    GlassTea: '\f345',
    Globe: '\f347',
    Gold: '\f348',
    GolfBall: '\f349',
    Google: '\f34f',
    GoogleDrive: '\f34b',
    GoogleDriveAlt: '\f34a',
    GoogleHangouts: '\f34d',
    GoogleHangoutsAlt: '\f34c',
    GooglePlay: '\f34e',
    GraduationCap: '\f350',
    GraphBar: '\f351',
    Grid: '\f352',
    Grids: '\f353',
    Grin: '\f356',
    GrinTongueWink: '\f355',
    GrinTongueWinkAlt: '\f354',
    GripHorizontalLine: '\f357',
    HardHat: '\f358',
    Hdd: '\f359',
    HeadSide: '\f35c',
    HeadSideCough: '\f35a',
    HeadSideMask: '\f35b',
    HeadphoneSlash: '\f35d',
    Headphones: '\f35f',
    HeadphonesAlt: '\f35e',
    Heart: '\f365',
    HeartAlt: '\f360',
    HeartBreak: '\f361',
    HeartMedical: '\f362',
    HeartRate: '\f363',
    HeartSign: '\f364',
    Heartbeat: '\f366',
    HindiToChinese: '\f367',
    Hipchat: '\f368',
    Hipercard: '\f369',
    History: '\f36b',
    HistoryAlt: '\f36a',
    Home: '\f36d',
    HomeAlt: '\f36c',
    HorizontalAlignCenter: '\f36e',
    HorizontalAlignLeft: '\f36f',
    HorizontalAlignRight: '\f370',
    HorizontalDistributionCenter: '\f371',
    HorizontalDistributionLeft: '\f372',
    HorizontalDistributionRight: '\f373',
    Hospital: '\f376',
    HospitalSquareSign: '\f374',
    HospitalSymbol: '\f375',
    Hourglass: '\f377',
    HouseUser: '\f378',
    Html3: '\f37a',
    Html3Alt: '\f379',
    Html5: '\f37c',
    Html5Alt: '\f37b',
    Hunting: '\f37d',
    Icons: '\f37e',
    Illustration: '\f37f',
    Image: '\f394',
    ImageAltSlash: '\f380',
    ImageBlock: '\f381',
    ImageBroken: '\f382',
    ImageCheck: '\f383',
    ImageDownload: '\f384',
    ImageEdit: '\f385',
    ImageLock: '\f386',
    ImageMinus: '\f387',
    ImagePlus: '\f388',
    ImageQuestion: '\f389',
    ImageRedo: '\f38a',
    ImageResizeLandscape: '\f38b',
    ImageResizeSquare: '\f38c',
    ImageSearch: '\f38d',
    ImageShare: '\f38e',
    ImageShield: '\f38f',
    ImageSlash: '\f390',
    ImageTimes: '\f391',
    ImageUpload: '\f392',
    ImageV: '\f393',
    Images: '\f395',
    ImportAction: '\f396',
    Inbox: '\f397',
    IncomingCall: '\f398',
    Info: '\f39a',
    InfoCircle: '\f399',
    Instagram: '\f39c',
    InstagramAlt: '\f39b',
    Intercom: '\f39e',
    IntercomAlt: '\f39d',
    Invoice: '\f39f',
    Italic: '\f3a0',
    Jackhammer: '\f3a1',
    JavaScript: '\f3a2',
    Kayak: '\f3a3',
    KeySkeleton: '\f3a5',
    KeySkeletonAlt: '\f3a4',
    Keyboard: '\f3a9',
    KeyboardAlt: '\f3a6',
    KeyboardHide: '\f3a7',
    KeyboardShow: '\f3a8',
    KeyholeCircle: '\f3aa',
    KeyholeSquare: '\f3ac',
    KeyholeSquareFull: '\f3ab',
    Keylines: '\f3ad',
    Kid: '\f3ae',
    Label: '\f3b0',
    LabelAlt: '\f3af',
    Lamp: '\f3b1',
    Language: '\f3b2',
    Laptop: '\f3b5',
    LaptopCloud: '\f3b3',
    LaptopConnection: '\f3b4',
    Laughing: '\f3b6',
    LayerGroup: '\f3b8',
    LayerGroupSlash: '\f3b7',
    Layers: '\f3bb',
    LayersAlt: '\f3b9',
    LayersSlash: '\f3ba',
    LeftArrowFromLeft: '\f3bc',
    LeftArrowToLeft: '\f3bd',
    LeftIndent: '\f3bf',
    LeftIndentAlt: '\f3be',
    LeftToRightTextDirection: '\f3c0',
    LetterChineseA: '\f3c1',
    LetterEnglishA: '\f3c2',
    LetterHindiA: '\f3c3',
    LetterJapaneseA: '\f3c4',
    LifeRing: '\f3c5',
    Lightbulb: '\f3c7',
    LightbulbAlt: '\f3c6',
    Like: '\f3c8',
    Line: '\f3cb',
    LineAlt: '\f3c9',
    LineSpacing: '\f3ca',
    Link: '\f3d0',
    LinkAdd: '\f3cc',
    LinkAlt: '\f3cd',
    LinkBroken: '\f3ce',
    LinkH: '\f3cf',
    Linkedin: '\f3d2',
    LinkedinAlt: '\f3d1',
    Linux: '\f3d3',
    LiraSign: '\f3d4',
    ListOl: '\f3d5',
    ListUiAlt: '\f3d6',
    ListUl: '\f3d7',
    Load: '\f3d8',
    LocationArrow: '\f3da',
    LocationArrowAlt: '\f3d9',
    LocationPinAlt: '\f3db',
    LocationPoint: '\f3dc',
    Lock: '\f3e1',
    LockAccess: '\f3dd',
    LockAlt: '\f3de',
    LockOpenAlt: '\f3df',
    LockSlash: '\f3e0',
    Lottiefiles: '\f3e3',
    LottiefilesAlt: '\f3e2',
    LuggageCart: '\f3e4',
    Mailbox: '\f3e6',
    MailboxAlt: '\f3e5',
    Map: '\f3f2',
    MapMarker: '\f3ef',
    MapMarkerAlt: '\f3e7',
    MapMarkerEdit: '\f3e8',
    MapMarkerInfo: '\f3e9',
    MapMarkerMinus: '\f3ea',
    MapMarkerPlus: '\f3eb',
    MapMarkerQuestion: '\f3ec',
    MapMarkerShield: '\f3ed',
    MapMarkerSlash: '\f3ee',
    MapPin: '\f3f1',
    MapPinAlt: '\f3f0',
    Mars: '\f3f3',
    MasterCard: '\f3f4',
    Mastercard: '\f3f5',
    MaximizeLeft: '\f3f6',
    Medal: '\f3f7',
    MedicalDrip: '\f3f8',
    MedicalSquare: '\f3fa',
    MedicalSquareFull: '\f3f9',
    MediumM: '\f3fb',
    Medkit: '\f3fc',
    MeetingBoard: '\f3fd',
    Megaphone: '\f3fe',
    Meh: '\f401',
    MehAlt: '\f3ff',
    MehClosedEye: '\f400',
    Message: '\f402',
    Metro: '\f403',
    Microphone: '\f405',
    MicrophoneSlash: '\f404',
    Microscope: '\f406',
    Microsoft: '\f407',
    Minus: '\f40c',
    MinusCircle: '\f408',
    MinusPath: '\f409',
    MinusSquare: '\f40b',
    MinusSquareFull: '\f40a',
    MissedCall: '\f40d',
    MobileAndroid: '\f40f',
    MobileAndroidAlt: '\f40e',
    MobileVibrate: '\f410',
    Modem: '\f411',
    MoneyBill: '\f414',
    MoneyBillSlash: '\f412',
    MoneyBillStack: '\f413',
    MoneyInsert: '\f415',
    MoneyStack: '\f416',
    MoneyWithdraw: '\f417',
    MoneyWithdrawal: '\f418',
    Moneybag: '\f41a',
    MoneybagAlt: '\f419',
    Monitor: '\f41c',
    MonitorHeartRate: '\f41b',
    Moon: '\f41e',
    MoonEclipse: '\f41d',
    Moonset: '\f41f',
    Mountains: '\f421',
    MountainsSun: '\f420',
    Mouse: '\f424',
    MouseAlt: '\f423',
    MouseAlt2: '\f422',
    Multiply: '\f425',
    Music: '\f428',
    MusicNote: '\f426',
    MusicTuneSlash: '\f427',
    NA: '\f429',
    Navigator: '\f42a',
    Nerd: '\f42b',
    Newspaper: '\f42c',
    Ninja: '\f42d',
    NoEntry: '\f42e',
    Notebooks: '\f42f',
    Notes: '\f430',
    ObjectGroup: '\f431',
    ObjectUngroup: '\f432',
    Octagon: '\f433',
    Okta: '\f434',
    Opera: '\f436',
    OperaAlt: '\f435',
    OutgoingCall: '\f437',
    Packet: '\f438',
    Padlock: '\f439',
    Pagelines: '\f43a',
    Pagerduty: '\f43b',
    PaintTool: '\f43c',
    Palette: '\f43d',
    PanelAdd: '\f43e',
    PanoramaH: '\f440',
    PanoramaHAlt: '\f43f',
    PanoramaV: '\f441',
    Paperclip: '\f442',
    Paragraph: '\f443',
    Parcel: '\f444',
    ParkingCircle: '\f445',
    ParkingSquare: '\f446',
    Pathfinder: '\f448',
    PathfinderUnite: '\f447',
    Pause: '\f44a',
    PauseCircle: '\f449',
    Paypal: '\f44b',
    Pen: '\f44c',
    Pentagon: '\f44d',
    Percent: '\f44e',
    Percentage: '\f44f',
    PetzArgan: '\f450',
    PetzBag: '\f451',
    PetzBanhoETosa: '\f452',
    PetzBarcode: '\f453',
    PetzBath: '\f454',
    PetzCalendar: '\f455',
    PetzCancel: '\f456',
    PetzCard: '\f457',
    PetzChevronDown: '\f458',
    PetzChevronLeft: '\f459',
    PetzChevronRight: '\f45a',
    PetzChevronUp: '\f45b',
    PetzClubz: '\f45c',
    PetzCoupon: '\f45d',
    PetzDelivery: '\f45e',
    PetzDiscount: '\f45f',
    PetzEscape: '\f460',
    PetzFaq: '\f461',
    PetzFavorite: '\f462',
    PetzFilter: '\f463',
    PetzFunnel: '\f464',
    PetzFurminator: '\f465',
    PetzGrooming: '\f466',
    PetzHydra: '\f467',
    PetzLocation: '\f468',
    PetzMapMarker: '\f469',
    PetzMenu: '\f46a',
    PetzMinus: '\f46b',
    PetzMyPet: '\f46c',
    PetzNotification: '\f46d',
    PetzOrder: '\f46e',
    PetzPackage: '\f46f',
    PetzPix: '\f470',
    PetzProfile: '\f471',
    PetzRebuy: '\f472',
    PetzRecompra: '\f473',
    PetzRefresh: '\f474',
    PetzScissors: '\f475',
    PetzSearch: '\f476',
    PetzShaver: '\f477',
    PetzShop: '\f478',
    PetzStripping: '\f479',
    PetzSubscription: '\f47a',
    PetzToning: '\f47b',
    PetzTrash: '\f47c',
    PetzTune: '\f47d',
    PetzVetCardio: '\f47e',
    PetzVetKit: '\f47f',
    PetzVetPhone: '\f480',
    Phone: '\f486',
    PhoneAlt: '\f481',
    PhonePause: '\f482',
    PhoneSlash: '\f483',
    PhoneTimes: '\f484',
    PhoneVolume: '\f485',
    Picture: '\f487',
    Pinterest: '\f488',
    PizzaSlice: '\f489',
    Plane: '\f48d',
    PlaneArrival: '\f48a',
    PlaneDeparture: '\f48b',
    PlaneFly: '\f48c',
    Play: '\f48f',
    PlayCircle: '\f48e',
    Plug: '\f490',
    Plus: '\f493',
    PlusCircle: '\f491',
    PlusSquare: '\f492',
    Podium: '\f494',
    Polygon: '\f495',
    PostStamp: '\f496',
    Postcard: '\f497',
    Pound: '\f499',
    PoundCircle: '\f498',
    Power: '\f49a',
    PrescriptionBottle: '\f49b',
    Presentation: '\f4a4',
    PresentationCheck: '\f49c',
    PresentationEdit: '\f49d',
    PresentationLine: '\f49e',
    PresentationLinesAlt: '\f49f',
    PresentationMinus: '\f4a0',
    PresentationPlay: '\f4a1',
    PresentationPlus: '\f4a2',
    PresentationTimes: '\f4a3',
    Previous: '\f4a5',
    PricetagAlt: '\f4a6',
    Print: '\f4a8',
    PrintSlash: '\f4a7',
    Process: '\f4a9',
    Processor: '\f4aa',
    ProgrammingLanguage: '\f4ab',
    Pump: '\f4ac',
    PuzzlePiece: '\f4ad',
    QrcodeScan: '\f4ae',
    Question: '\f4b0',
    QuestionCircle: '\f4af',
    Rainbow: '\f4b1',
    Raindrops: '\f4b3',
    RaindropsAlt: '\f4b2',
    React: '\f4b4',
    Receipt: '\f4b6',
    ReceiptAlt: '\f4b5',
    RecordAudio: '\f4b7',
    RedditAlienAlt: '\f4b8',
    Redo: '\f4b9',
    Refresh: '\f4ba',
    Registered: '\f4bb',
    Repeat: '\f4bc',
    Restaurant: '\f4bd',
    RightIndentAlt: '\f4be',
    RightToLeftTextDirection: '\f4bf',
    Robot: '\f4c0',
    Rocket: '\f4c1',
    RopeWay: '\f4c2',
    Rotate360: '\f4c3',
    RotateScreen: '\f4c4',
    Rss: '\f4c7',
    RssAlt: '\f4c5',
    RssInterface: '\f4c6',
    Ruler: '\f4c9',
    RulerCombined: '\f4c8',
    RupeeSign: '\f4ca',
    Sad: '\f4cf',
    SadCry: '\f4cb',
    SadCrying: '\f4cc',
    SadDizzy: '\f4cd',
    SadSquint: '\f4ce',
    Sanitizer: '\f4d1',
    SanitizerAlt: '\f4d0',
    Save: '\f4d2',
    ScalingLeft: '\f4d3',
    ScalingRight: '\f4d4',
    Scenery: '\f4d5',
    Schedule: '\f4d6',
    Screw: '\f4d7',
    Scroll: '\f4d9',
    ScrollH: '\f4d8',
    Search: '\f4dd',
    SearchAlt: '\f4da',
    SearchMinus: '\f4db',
    SearchPlus: '\f4dc',
    Selfie: '\f4de',
    Server: '\f4e3',
    ServerAlt: '\f4df',
    ServerConnection: '\f4e0',
    ServerNetwork: '\f4e2',
    ServerNetworkAlt: '\f4e1',
    Servers: '\f4e4',
    Servicemark: '\f4e5',
    Setting: '\f4e6',
    SeventeenPlus: '\f4e7',
    Share: '\f4e9',
    ShareAlt: '\f4e8',
    Shelf: '\f4ea',
    Shield: '\f4f0',
    ShieldCheck: '\f4eb',
    ShieldExclamation: '\f4ec',
    ShieldPlus: '\f4ed',
    ShieldQuestion: '\f4ee',
    ShieldSlash: '\f4ef',
    Ship: '\f4f1',
    Shop: '\f4f2',
    ShoppingBag: '\f4f3',
    ShoppingBasket: '\f4f4',
    ShoppingCart: '\f4f6',
    ShoppingCartAlt: '\f4f5',
    Shovel: '\f4f7',
    Shrink: '\f4f8',
    Shuffle: '\f4f9',
    Shutter: '\f4fb',
    ShutterAlt: '\f4fa',
    Sick: '\f4fc',
    SideMenu: '\f4fd',
    Sigma: '\f4fe',
    SignAlt: '\f4ff',
    SignInAlt: '\f500',
    SignLeft: '\f501',
    SignOutAlt: '\f502',
    SignRight: '\f503',
    Signal: '\f506',
    SignalAlt: '\f505',
    SignalAlt3: '\f504',
    Signin: '\f507',
    Signout: '\f508',
    Silence: '\f509',
    SilentSquint: '\f50a',
    SimCard: '\f50b',
    Sitemap: '\f50c',
    SixPlus: '\f50d',
    SixteenPlus: '\f50e',
    SkipForward: '\f511',
    SkipForwardAlt: '\f50f',
    SkipForwardCircle: '\f510',
    Skype: '\f513',
    SkypeAlt: '\f512',
    Slack: '\f515',
    SlackAlt: '\f514',
    SliderH: '\f517',
    SliderHRange: '\f516',
    SlidersV: '\f519',
    SlidersVAlt: '\f518',
    Smile: '\f520',
    SmileBeam: '\f51a',
    SmileDizzy: '\f51b',
    SmileSquintWink: '\f51d',
    SmileSquintWinkAlt: '\f51c',
    SmileWink: '\f51f',
    SmileWinkAlt: '\f51e',
    SnapchatAlt: '\f521',
    SnapchatGhost: '\f522',
    SnapchatSquare: '\f523',
    SnowFlake: '\f524',
    Snowflake: '\f526',
    SnowflakeAlt: '\f525',
    SocialDistancing: '\f527',
    Sort: '\f52a',
    SortAmountDown: '\f528',
    SortAmountUp: '\f529',
    Sorting: '\f52b',
    SpaceKey: '\f52c',
    Spade: '\f52d',
    Sperms: '\f52e',
    Spin: '\f52f',
    Spinner: '\f531',
    SpinnerAlt: '\f530',
    Square: '\f534',
    SquareFull: '\f532',
    SquareShape: '\f533',
    Squint: '\f535',
    Star: '\f537',
    StarHalfAlt: '\f536',
    StepBackward: '\f53a',
    StepBackwardAlt: '\f538',
    StepBackwardCircle: '\f539',
    StepForward: '\f53b',
    Stethoscope: '\f53d',
    StethoscopeAlt: '\f53c',
    StopCircle: '\f53e',
    Stopwatch: '\f540',
    StopwatchSlash: '\f53f',
    Store: '\f543',
    StoreAlt: '\f541',
    StoreSlash: '\f542',
    Streering: '\f544',
    Stretcher: '\f545',
    Subject: '\f546',
    Subway: '\f548',
    SubwayAlt: '\f547',
    Suitcase: '\f54a',
    SuitcaseAlt: '\f549',
    Sun: '\f54b',
    Sunset: '\f54c',
    Surprise: '\f54d',
    Swatchbook: '\f54e',
    Swiggy: '\f54f',
    Swimmer: '\f550',
    Sync: '\f553',
    SyncExclamation: '\f551',
    SyncSlash: '\f552',
    Syringe: '\f554',
    Table: '\f556',
    TableTennis: '\f555',
    Tablet: '\f557',
    Tablets: '\f558',
    TachometerFast: '\f55a',
    TachometerFastAlt: '\f559',
    Tag: '\f55c',
    TagAlt: '\f55b',
    Tape: '\f55d',
    Taxi: '\f55e',
    Tear: '\f55f',
    Telegram: '\f561',
    TelegramAlt: '\f560',
    Telescope: '\f562',
    Temperature: '\f569',
    TemperatureEmpty: '\f563',
    TemperatureHalf: '\f564',
    TemperatureMinus: '\f565',
    TemperaturePlus: '\f566',
    TemperatureQuarter: '\f567',
    TemperatureThreeQuarter: '\f568',
    TenPlus: '\f56a',
    TennisBall: '\f56b',
    Text: '\f56f',
    TextFields: '\f56c',
    TextSize: '\f56d',
    TextStrikeThrough: '\f56e',
    Th: '\f572',
    ThLarge: '\f570',
    ThSlash: '\f571',
    Thermometer: '\f573',
    ThirteenPlus: '\f574',
    ThreePlus: '\f575',
    ThumbsDown: '\f576',
    ThumbsUp: '\f577',
    Thunderstorm: '\f57a',
    ThunderstormMoon: '\f578',
    ThunderstormSun: '\f579',
    Ticket: '\f57b',
    Tiktok: '\f57c',
    Times: '\f57f',
    TimesCircle: '\f57d',
    TimesSquare: '\f57e',
    ToggleOff: '\f580',
    ToggleOn: '\f581',
    ToiletPaper: '\f582',
    TopArrowFromTop: '\f583',
    TopArrowToTop: '\f584',
    Tornado: '\f585',
    Trademark: '\f587',
    TrademarkCircle: '\f586',
    TrafficBarrier: '\f588',
    TrafficLight: '\f589',
    Transaction: '\f58a',
    Trash: '\f58c',
    TrashAlt: '\f58b',
    Trees: '\f58d',
    Triangle: '\f58e',
    Trophy: '\f58f',
    Trowel: '\f590',
    Truck: '\f592',
    TruckLoading: '\f591',
    Tumblr: '\f595',
    TumblrAlt: '\f593',
    TumblrSquare: '\f594',
    TvRetro: '\f597',
    TvRetroSlash: '\f596',
    TwelvePlus: '\f598',
    TwentyonePlus: '\f599',
    Twitter: '\f59a',
    Umbrella: '\f59b',
    Unamused: '\f59c',
    Underline: '\f59d',
    University: '\f59e',
    Unlock: '\f5a0',
    UnlockAlt: '\f59f',
    Upload: '\f5a2',
    UploadAlt: '\f5a1',
    UsdCircle: '\f5a3',
    UsdSquare: '\f5a4',
    User: '\f5b0',
    UserArrows: '\f5a5',
    UserCheck: '\f5a6',
    UserCircle: '\f5a7',
    UserExclamation: '\f5a8',
    UserLocation: '\f5a9',
    UserMd: '\f5aa',
    UserMinus: '\f5ab',
    UserNurse: '\f5ac',
    UserPlus: '\f5ad',
    UserSquare: '\f5ae',
    UserTimes: '\f5af',
    UsersAlt: '\f5b1',
    Utensils: '\f5b3',
    UtensilsAlt: '\f5b2',
    VectorSquare: '\f5b5',
    VectorSquareAlt: '\f5b4',
    Venus: '\f5b6',
    VerticalAlignBottom: '\f5b7',
    VerticalAlignCenter: '\f5b8',
    VerticalAlignTop: '\f5b9',
    VerticalDistributeBottom: '\f5ba',
    VerticalDistributionCenter: '\f5bb',
    VerticalDistributionTop: '\f5bc',
    Video: '\f5bf',
    VideoQuestion: '\f5bd',
    VideoSlash: '\f5be',
    VirusSlash: '\f5c0',
    Visa: '\f5c1',
    VisualStudio: '\f5c2',
    Vk: '\f5c4',
    VkAlt: '\f5c3',
    Voicemail: '\f5c6',
    VoicemailRectangle: '\f5c5',
    Volume: '\f5cb',
    VolumeDown: '\f5c7',
    VolumeMute: '\f5c8',
    VolumeOff: '\f5c9',
    VolumeUp: '\f5ca',
    Vuejs: '\f5cd',
    VuejsAlt: '\f5cc',
    Wall: '\f5ce',
    Wallet: '\f5cf',
    Watch: '\f5d1',
    WatchAlt: '\f5d0',
    Water: '\f5d4',
    WaterDropSlash: '\f5d2',
    WaterGlass: '\f5d3',
    WebGrid: '\f5d6',
    WebGridAlt: '\f5d5',
    WebSection: '\f5d8',
    WebSectionAlt: '\f5d7',
    Webcam: '\f5d9',
    Weight: '\f5da',
    Whatsapp: '\f5dc',
    WhatsappAlt: '\f5db',
    WheelBarrow: '\f5dd',
    Wheelchair: '\f5df',
    WheelchairAlt: '\f5de',
    Wifi: '\f5e2',
    WifiRouter: '\f5e0',
    WifiSlash: '\f5e1',
    Wind: '\f5e5',
    WindMoon: '\f5e3',
    WindSun: '\f5e4',
    Window: '\f5e9',
    WindowGrid: '\f5e6',
    WindowMaximize: '\f5e7',
    WindowSection: '\f5e8',
    Windows: '\f5ea',
    Windsock: '\f5eb',
    Windy: '\f5ec',
    Wordpress: '\f5ee',
    WordpressSimple: '\f5ed',
    WrapText: '\f5ef',
    Wrench: '\f5f0',
    X: '\f5f2',
    XAdd: '\f5f1',
    Yen: '\f5f4',
    YenCircle: '\f5f3',
    YinYang: '\f5f5',
    Youtube: '\f5f6',
    ZeroPlus: '\f5f7'
};
var DSIconsLineConfig;
(function (DSIconsLineConfig) {
    DSIconsLineConfig["Abacus"] = "abacus";
    DSIconsLineConfig["AccessibleIconAlt"] = "accessible-icon-alt";
    DSIconsLineConfig["Adjust"] = "adjust";
    DSIconsLineConfig["AdjustAlt"] = "adjust-alt";
    DSIconsLineConfig["AdjustCircle"] = "adjust-circle";
    DSIconsLineConfig["AdjustHalf"] = "adjust-half";
    DSIconsLineConfig["Adobe"] = "adobe";
    DSIconsLineConfig["AdobeAlt"] = "adobe-alt";
    DSIconsLineConfig["Airplay"] = "airplay";
    DSIconsLineConfig["Align"] = "align";
    DSIconsLineConfig["AlignAlt"] = "align-alt";
    DSIconsLineConfig["AlignCenter"] = "align-center";
    DSIconsLineConfig["AlignCenterAlt"] = "align-center-alt";
    DSIconsLineConfig["AlignCenterH"] = "align-center-h";
    DSIconsLineConfig["AlignCenterJustify"] = "align-center-justify";
    DSIconsLineConfig["AlignCenterV"] = "align-center-v";
    DSIconsLineConfig["AlignJustify"] = "align-justify";
    DSIconsLineConfig["AlignLeft"] = "align-left";
    DSIconsLineConfig["AlignLeftJustify"] = "align-left-justify";
    DSIconsLineConfig["AlignLetterRight"] = "align-letter-right";
    DSIconsLineConfig["AlignRight"] = "align-right";
    DSIconsLineConfig["AlignRightJustify"] = "align-right-justify";
    DSIconsLineConfig["Amazon"] = "amazon";
    DSIconsLineConfig["Ambulance"] = "ambulance";
    DSIconsLineConfig["AmericanExpress"] = "american-express";
    DSIconsLineConfig["Analysis"] = "analysis";
    DSIconsLineConfig["Analytics"] = "analytics";
    DSIconsLineConfig["Anchor"] = "anchor";
    DSIconsLineConfig["Android"] = "android";
    DSIconsLineConfig["AndroidAlt"] = "android-alt";
    DSIconsLineConfig["AndroidPhoneSlash"] = "android-phone-slash";
    DSIconsLineConfig["AngleDoubleDown"] = "angle-double-down";
    DSIconsLineConfig["AngleDoubleLeft"] = "angle-double-left";
    DSIconsLineConfig["AngleDoubleRight"] = "angle-double-right";
    DSIconsLineConfig["AngleDoubleUp"] = "angle-double-up";
    DSIconsLineConfig["AngleDown"] = "angle-down";
    DSIconsLineConfig["AngleLeft"] = "angle-left";
    DSIconsLineConfig["AngleLeftB"] = "angle-left-b";
    DSIconsLineConfig["AngleRight"] = "angle-right";
    DSIconsLineConfig["AngleRightB"] = "angle-right-b";
    DSIconsLineConfig["AngleUp"] = "angle-up";
    DSIconsLineConfig["Angry"] = "angry";
    DSIconsLineConfig["Ankh"] = "ankh";
    DSIconsLineConfig["Annoyed"] = "annoyed";
    DSIconsLineConfig["AnnoyedAlt"] = "annoyed-alt";
    DSIconsLineConfig["Apple"] = "apple";
    DSIconsLineConfig["AppleAlt"] = "apple-alt";
    DSIconsLineConfig["Apps"] = "apps";
    DSIconsLineConfig["Archive"] = "archive";
    DSIconsLineConfig["ArchiveAlt"] = "archive-alt";
    DSIconsLineConfig["Archway"] = "archway";
    DSIconsLineConfig["Arrow"] = "arrow";
    DSIconsLineConfig["ArrowBreak"] = "arrow-break";
    DSIconsLineConfig["ArrowCircleDown"] = "arrow-circle-down";
    DSIconsLineConfig["ArrowCircleLeft"] = "arrow-circle-left";
    DSIconsLineConfig["ArrowCircleRight"] = "arrow-circle-right";
    DSIconsLineConfig["ArrowCircleUp"] = "arrow-circle-up";
    DSIconsLineConfig["ArrowCompressH"] = "arrow-compress-h";
    DSIconsLineConfig["ArrowDown"] = "arrow-down";
    DSIconsLineConfig["ArrowDownLeft"] = "arrow-down-left";
    DSIconsLineConfig["ArrowDownRight"] = "arrow-down-right";
    DSIconsLineConfig["ArrowFromRight"] = "arrow-from-right";
    DSIconsLineConfig["ArrowFromTop"] = "arrow-from-top";
    DSIconsLineConfig["ArrowGrowth"] = "arrow-growth";
    DSIconsLineConfig["ArrowLeft"] = "arrow-left";
    DSIconsLineConfig["ArrowRandom"] = "arrow-random";
    DSIconsLineConfig["ArrowResizeDiagonal"] = "arrow-resize-diagonal";
    DSIconsLineConfig["ArrowRight"] = "arrow-right";
    DSIconsLineConfig["ArrowToBottom"] = "arrow-to-bottom";
    DSIconsLineConfig["ArrowToRight"] = "arrow-to-right";
    DSIconsLineConfig["ArrowUp"] = "arrow-up";
    DSIconsLineConfig["ArrowUpLeft"] = "arrow-up-left";
    DSIconsLineConfig["ArrowUpRight"] = "arrow-up-right";
    DSIconsLineConfig["ArrowsH"] = "arrows-h";
    DSIconsLineConfig["ArrowsHAlt"] = "arrows-h-alt";
    DSIconsLineConfig["ArrowsLeftDown"] = "arrows-left-down";
    DSIconsLineConfig["ArrowsMaximize"] = "arrows-maximize";
    DSIconsLineConfig["ArrowsMerge"] = "arrows-merge";
    DSIconsLineConfig["ArrowsResize"] = "arrows-resize";
    DSIconsLineConfig["ArrowsResizeH"] = "arrows-resize-h";
    DSIconsLineConfig["ArrowsResizeV"] = "arrows-resize-v";
    DSIconsLineConfig["ArrowsRightDown"] = "arrows-right-down";
    DSIconsLineConfig["ArrowsShrinkH"] = "arrows-shrink-h";
    DSIconsLineConfig["ArrowsShrinkV"] = "arrows-shrink-v";
    DSIconsLineConfig["ArrowsUpRight"] = "arrows-up-right";
    DSIconsLineConfig["ArrowsV"] = "arrows-v";
    DSIconsLineConfig["ArrowsVAlt"] = "arrows-v-alt";
    DSIconsLineConfig["AssistiveListeningSystems"] = "assistive-listening-systems";
    DSIconsLineConfig["Asterisk"] = "asterisk";
    DSIconsLineConfig["At"] = "at";
    DSIconsLineConfig["Atom"] = "atom";
    DSIconsLineConfig["AutoFlash"] = "auto-flash";
    DSIconsLineConfig["Award"] = "award";
    DSIconsLineConfig["AwardAlt"] = "award-alt";
    DSIconsLineConfig["BabyCarriage"] = "baby-carriage";
    DSIconsLineConfig["Backpack"] = "backpack";
    DSIconsLineConfig["Backspace"] = "backspace";
    DSIconsLineConfig["Backward"] = "backward";
    DSIconsLineConfig["Bag"] = "bag";
    DSIconsLineConfig["BagAlt"] = "bag-alt";
    DSIconsLineConfig["BagSlash"] = "bag-slash";
    DSIconsLineConfig["BalanceScale"] = "balance-scale";
    DSIconsLineConfig["Ban"] = "ban";
    DSIconsLineConfig["BandAid"] = "band-aid";
    DSIconsLineConfig["Bars"] = "bars";
    DSIconsLineConfig["BaseballBall"] = "baseball-ball";
    DSIconsLineConfig["Basketball"] = "basketball";
    DSIconsLineConfig["BasketballHoop"] = "basketball-hoop";
    DSIconsLineConfig["Bath"] = "bath";
    DSIconsLineConfig["BatteryBolt"] = "battery-bolt";
    DSIconsLineConfig["BatteryEmpty"] = "battery-empty";
    DSIconsLineConfig["Bed"] = "bed";
    DSIconsLineConfig["BedDouble"] = "bed-double";
    DSIconsLineConfig["Behance"] = "behance";
    DSIconsLineConfig["BehanceAlt"] = "behance-alt";
    DSIconsLineConfig["Bell"] = "bell";
    DSIconsLineConfig["BellSchool"] = "bell-school";
    DSIconsLineConfig["BellSlash"] = "bell-slash";
    DSIconsLineConfig["Bill"] = "bill";
    DSIconsLineConfig["Bing"] = "bing";
    DSIconsLineConfig["Bitcoin"] = "bitcoin";
    DSIconsLineConfig["BitcoinAlt"] = "bitcoin-alt";
    DSIconsLineConfig["BitcoinCircle"] = "bitcoin-circle";
    DSIconsLineConfig["BitcoinSign"] = "bitcoin-sign";
    DSIconsLineConfig["BlackBerry"] = "black-berry";
    DSIconsLineConfig["Blogger"] = "blogger";
    DSIconsLineConfig["BloggerAlt"] = "blogger-alt";
    DSIconsLineConfig["BluetoothB"] = "bluetooth-b";
    DSIconsLineConfig["Bold"] = "bold";
    DSIconsLineConfig["Boleto"] = "boleto";
    DSIconsLineConfig["Bolt"] = "bolt";
    DSIconsLineConfig["BoltAlt"] = "bolt-alt";
    DSIconsLineConfig["BoltSlash"] = "bolt-slash";
    DSIconsLineConfig["Book"] = "book";
    DSIconsLineConfig["BookAlt"] = "book-alt";
    DSIconsLineConfig["BookMedical"] = "book-medical";
    DSIconsLineConfig["BookOpen"] = "book-open";
    DSIconsLineConfig["BookReader"] = "book-reader";
    DSIconsLineConfig["Bookmark"] = "bookmark";
    DSIconsLineConfig["BookmarkFull"] = "bookmark-full";
    DSIconsLineConfig["Books"] = "books";
    DSIconsLineConfig["Boombox"] = "boombox";
    DSIconsLineConfig["BorderAlt"] = "border-alt";
    DSIconsLineConfig["BorderBottom"] = "border-bottom";
    DSIconsLineConfig["BorderClear"] = "border-clear";
    DSIconsLineConfig["BorderHorizontal"] = "border-horizontal";
    DSIconsLineConfig["BorderInner"] = "border-inner";
    DSIconsLineConfig["BorderLeft"] = "border-left";
    DSIconsLineConfig["BorderOut"] = "border-out";
    DSIconsLineConfig["BorderRight"] = "border-right";
    DSIconsLineConfig["BorderTop"] = "border-top";
    DSIconsLineConfig["BorderVertical"] = "border-vertical";
    DSIconsLineConfig["BowlingBall"] = "bowling-ball";
    DSIconsLineConfig["Box"] = "box";
    DSIconsLineConfig["BracketsCurly"] = "brackets-curly";
    DSIconsLineConfig["Brain"] = "brain";
    DSIconsLineConfig["Briefcase"] = "briefcase";
    DSIconsLineConfig["BriefcaseAlt"] = "briefcase-alt";
    DSIconsLineConfig["Bright"] = "bright";
    DSIconsLineConfig["Brightness"] = "brightness";
    DSIconsLineConfig["BrightnessEmpty"] = "brightness-empty";
    DSIconsLineConfig["BrightnessHalf"] = "brightness-half";
    DSIconsLineConfig["BrightnessLow"] = "brightness-low";
    DSIconsLineConfig["BrightnessMinus"] = "brightness-minus";
    DSIconsLineConfig["BrightnessPlus"] = "brightness-plus";
    DSIconsLineConfig["BringBottom"] = "bring-bottom";
    DSIconsLineConfig["BringFront"] = "bring-front";
    DSIconsLineConfig["Browser"] = "browser";
    DSIconsLineConfig["BrushAlt"] = "brush-alt";
    DSIconsLineConfig["Bug"] = "bug";
    DSIconsLineConfig["Building"] = "building";
    DSIconsLineConfig["Bullseye"] = "bullseye";
    DSIconsLineConfig["Bus"] = "bus";
    DSIconsLineConfig["BusAlt"] = "bus-alt";
    DSIconsLineConfig["BusSchool"] = "bus-school";
    DSIconsLineConfig["Calculator"] = "calculator";
    DSIconsLineConfig["CalculatorAlt"] = "calculator-alt";
    DSIconsLineConfig["CalendarAlt"] = "calendar-alt";
    DSIconsLineConfig["CalendarSlash"] = "calendar-slash";
    DSIconsLineConfig["Calender"] = "calender";
    DSIconsLineConfig["Calling"] = "calling";
    DSIconsLineConfig["Camera"] = "camera";
    DSIconsLineConfig["CameraChange"] = "camera-change";
    DSIconsLineConfig["CameraPlus"] = "camera-plus";
    DSIconsLineConfig["CameraSlash"] = "camera-slash";
    DSIconsLineConfig["Cancel"] = "cancel";
    DSIconsLineConfig["Capsule"] = "capsule";
    DSIconsLineConfig["Capture"] = "capture";
    DSIconsLineConfig["Car"] = "car";
    DSIconsLineConfig["CarSideview"] = "car-sideview";
    DSIconsLineConfig["CarSlash"] = "car-slash";
    DSIconsLineConfig["CarWash"] = "car-wash";
    DSIconsLineConfig["CardAtm"] = "card-atm";
    DSIconsLineConfig["CaretRight"] = "caret-right";
    DSIconsLineConfig["Cell"] = "cell";
    DSIconsLineConfig["Celsius"] = "celsius";
    DSIconsLineConfig["Channel"] = "channel";
    DSIconsLineConfig["ChannelAdd"] = "channel-add";
    DSIconsLineConfig["Chart"] = "chart";
    DSIconsLineConfig["ChartBar"] = "chart-bar";
    DSIconsLineConfig["ChartBarAlt"] = "chart-bar-alt";
    DSIconsLineConfig["ChartDown"] = "chart-down";
    DSIconsLineConfig["ChartGrowth"] = "chart-growth";
    DSIconsLineConfig["ChartGrowthAlt"] = "chart-growth-alt";
    DSIconsLineConfig["ChartLine"] = "chart-line";
    DSIconsLineConfig["ChartPie"] = "chart-pie";
    DSIconsLineConfig["ChartPieAlt"] = "chart-pie-alt";
    DSIconsLineConfig["Chat"] = "chat";
    DSIconsLineConfig["ChatBubbleUser"] = "chat-bubble-user";
    DSIconsLineConfig["ChatInfo"] = "chat-info";
    DSIconsLineConfig["Check"] = "check";
    DSIconsLineConfig["CheckCircle"] = "check-circle";
    DSIconsLineConfig["CheckSquare"] = "check-square";
    DSIconsLineConfig["Circle"] = "circle";
    DSIconsLineConfig["CircleLayer"] = "circle-layer";
    DSIconsLineConfig["Circuit"] = "circuit";
    DSIconsLineConfig["ClapperBoard"] = "clapper-board";
    DSIconsLineConfig["ClinicMedical"] = "clinic-medical";
    DSIconsLineConfig["Clipboard"] = "clipboard";
    DSIconsLineConfig["ClipboardAlt"] = "clipboard-alt";
    DSIconsLineConfig["ClipboardBlank"] = "clipboard-blank";
    DSIconsLineConfig["ClipboardNotes"] = "clipboard-notes";
    DSIconsLineConfig["Clock"] = "clock";
    DSIconsLineConfig["ClockEight"] = "clock-eight";
    DSIconsLineConfig["ClockFive"] = "clock-five";
    DSIconsLineConfig["ClockNine"] = "clock-nine";
    DSIconsLineConfig["ClockSeven"] = "clock-seven";
    DSIconsLineConfig["ClockTen"] = "clock-ten";
    DSIconsLineConfig["ClockThree"] = "clock-three";
    DSIconsLineConfig["ClockTwo"] = "clock-two";
    DSIconsLineConfig["ClosedCaptioning"] = "closed-captioning";
    DSIconsLineConfig["ClosedCaptioningSlash"] = "closed-captioning-slash";
    DSIconsLineConfig["Cloud"] = "cloud";
    DSIconsLineConfig["CloudBlock"] = "cloud-block";
    DSIconsLineConfig["CloudBookmark"] = "cloud-bookmark";
    DSIconsLineConfig["CloudCheck"] = "cloud-check";
    DSIconsLineConfig["CloudComputing"] = "cloud-computing";
    DSIconsLineConfig["CloudDataConnection"] = "cloud-data-connection";
    DSIconsLineConfig["CloudDatabaseTree"] = "cloud-database-tree";
    DSIconsLineConfig["CloudDownload"] = "cloud-download";
    DSIconsLineConfig["CloudDrizzle"] = "cloud-drizzle";
    DSIconsLineConfig["CloudExclamation"] = "cloud-exclamation";
    DSIconsLineConfig["CloudHail"] = "cloud-hail";
    DSIconsLineConfig["CloudHeart"] = "cloud-heart";
    DSIconsLineConfig["CloudInfo"] = "cloud-info";
    DSIconsLineConfig["CloudLock"] = "cloud-lock";
    DSIconsLineConfig["CloudMeatball"] = "cloud-meatball";
    DSIconsLineConfig["CloudMoon"] = "cloud-moon";
    DSIconsLineConfig["CloudMoonHail"] = "cloud-moon-hail";
    DSIconsLineConfig["CloudMoonMeatball"] = "cloud-moon-meatball";
    DSIconsLineConfig["CloudMoonRain"] = "cloud-moon-rain";
    DSIconsLineConfig["CloudMoonShowers"] = "cloud-moon-showers";
    DSIconsLineConfig["CloudQuestion"] = "cloud-question";
    DSIconsLineConfig["CloudRain"] = "cloud-rain";
    DSIconsLineConfig["CloudRainSun"] = "cloud-rain-sun";
    DSIconsLineConfig["CloudRedo"] = "cloud-redo";
    DSIconsLineConfig["CloudShare"] = "cloud-share";
    DSIconsLineConfig["CloudShield"] = "cloud-shield";
    DSIconsLineConfig["CloudShowers"] = "cloud-showers";
    DSIconsLineConfig["CloudShowersAlt"] = "cloud-showers-alt";
    DSIconsLineConfig["CloudShowersHeavy"] = "cloud-showers-heavy";
    DSIconsLineConfig["CloudSlash"] = "cloud-slash";
    DSIconsLineConfig["CloudSun"] = "cloud-sun";
    DSIconsLineConfig["CloudSunHail"] = "cloud-sun-hail";
    DSIconsLineConfig["CloudSunMeatball"] = "cloud-sun-meatball";
    DSIconsLineConfig["CloudSunRain"] = "cloud-sun-rain";
    DSIconsLineConfig["CloudSunRainAlt"] = "cloud-sun-rain-alt";
    DSIconsLineConfig["CloudSunTear"] = "cloud-sun-tear";
    DSIconsLineConfig["CloudTimes"] = "cloud-times";
    DSIconsLineConfig["CloudUnlock"] = "cloud-unlock";
    DSIconsLineConfig["CloudUpload"] = "cloud-upload";
    DSIconsLineConfig["CloudWifi"] = "cloud-wifi";
    DSIconsLineConfig["CloudWind"] = "cloud-wind";
    DSIconsLineConfig["Clouds"] = "clouds";
    DSIconsLineConfig["Club"] = "club";
    DSIconsLineConfig["Clubz"] = "clubz";
    DSIconsLineConfig["CodeBranch"] = "code-branch";
    DSIconsLineConfig["Coffee"] = "coffee";
    DSIconsLineConfig["Cog"] = "cog";
    DSIconsLineConfig["Coins"] = "coins";
    DSIconsLineConfig["Columns"] = "columns";
    DSIconsLineConfig["Comment"] = "comment";
    DSIconsLineConfig["CommentAdd"] = "comment-add";
    DSIconsLineConfig["CommentAlt"] = "comment-alt";
    DSIconsLineConfig["CommentAltBlock"] = "comment-alt-block";
    DSIconsLineConfig["CommentAltChartLines"] = "comment-alt-chart-lines";
    DSIconsLineConfig["CommentAltCheck"] = "comment-alt-check";
    DSIconsLineConfig["CommentAltDots"] = "comment-alt-dots";
    DSIconsLineConfig["CommentAltDownload"] = "comment-alt-download";
    DSIconsLineConfig["CommentAltEdit"] = "comment-alt-edit";
    DSIconsLineConfig["CommentAltExclamation"] = "comment-alt-exclamation";
    DSIconsLineConfig["CommentAltHeart"] = "comment-alt-heart";
    DSIconsLineConfig["CommentAltImage"] = "comment-alt-image";
    DSIconsLineConfig["CommentAltInfo"] = "comment-alt-info";
    DSIconsLineConfig["CommentAltLines"] = "comment-alt-lines";
    DSIconsLineConfig["CommentAltLock"] = "comment-alt-lock";
    DSIconsLineConfig["CommentAltMedical"] = "comment-alt-medical";
    DSIconsLineConfig["CommentAltMessage"] = "comment-alt-message";
    DSIconsLineConfig["CommentAltNotes"] = "comment-alt-notes";
    DSIconsLineConfig["CommentAltPlus"] = "comment-alt-plus";
    DSIconsLineConfig["CommentAltQuestion"] = "comment-alt-question";
    DSIconsLineConfig["CommentAltRedo"] = "comment-alt-redo";
    DSIconsLineConfig["CommentAltSearch"] = "comment-alt-search";
    DSIconsLineConfig["CommentAltShare"] = "comment-alt-share";
    DSIconsLineConfig["CommentAltShield"] = "comment-alt-shield";
    DSIconsLineConfig["CommentAltSlash"] = "comment-alt-slash";
    DSIconsLineConfig["CommentAltUpload"] = "comment-alt-upload";
    DSIconsLineConfig["CommentAltVerify"] = "comment-alt-verify";
    DSIconsLineConfig["CommentBlock"] = "comment-block";
    DSIconsLineConfig["CommentChartLine"] = "comment-chart-line";
    DSIconsLineConfig["CommentCheck"] = "comment-check";
    DSIconsLineConfig["CommentDots"] = "comment-dots";
    DSIconsLineConfig["CommentDownload"] = "comment-download";
    DSIconsLineConfig["CommentEdit"] = "comment-edit";
    DSIconsLineConfig["CommentExclamation"] = "comment-exclamation";
    DSIconsLineConfig["CommentHeart"] = "comment-heart";
    DSIconsLineConfig["CommentImage"] = "comment-image";
    DSIconsLineConfig["CommentInfo"] = "comment-info";
    DSIconsLineConfig["CommentInfoAlt"] = "comment-info-alt";
    DSIconsLineConfig["CommentLines"] = "comment-lines";
    DSIconsLineConfig["CommentLock"] = "comment-lock";
    DSIconsLineConfig["CommentMedical"] = "comment-medical";
    DSIconsLineConfig["CommentMessage"] = "comment-message";
    DSIconsLineConfig["CommentNotes"] = "comment-notes";
    DSIconsLineConfig["CommentPlus"] = "comment-plus";
    DSIconsLineConfig["CommentQuestion"] = "comment-question";
    DSIconsLineConfig["CommentRedo"] = "comment-redo";
    DSIconsLineConfig["CommentSearch"] = "comment-search";
    DSIconsLineConfig["CommentShare"] = "comment-share";
    DSIconsLineConfig["CommentShield"] = "comment-shield";
    DSIconsLineConfig["CommentSlash"] = "comment-slash";
    DSIconsLineConfig["CommentUpload"] = "comment-upload";
    DSIconsLineConfig["CommentVerify"] = "comment-verify";
    DSIconsLineConfig["Comments"] = "comments";
    DSIconsLineConfig["CommentsAlt"] = "comments-alt";
    DSIconsLineConfig["CompactDisc"] = "compact-disc";
    DSIconsLineConfig["Comparison"] = "comparison";
    DSIconsLineConfig["Compass"] = "compass";
    DSIconsLineConfig["Compress"] = "compress";
    DSIconsLineConfig["CompressAlt"] = "compress-alt";
    DSIconsLineConfig["CompressAltLeft"] = "compress-alt-left";
    DSIconsLineConfig["CompressArrows"] = "compress-arrows";
    DSIconsLineConfig["CompressLines"] = "compress-lines";
    DSIconsLineConfig["CompressPoint"] = "compress-point";
    DSIconsLineConfig["CompressV"] = "compress-v";
    DSIconsLineConfig["Confused"] = "confused";
    DSIconsLineConfig["Constructor"] = "constructor";
    DSIconsLineConfig["Copy"] = "copy";
    DSIconsLineConfig["CopyAlt"] = "copy-alt";
    DSIconsLineConfig["CopyLandscape"] = "copy-landscape";
    DSIconsLineConfig["Copyright"] = "copyright";
    DSIconsLineConfig["CornerDownLeft"] = "corner-down-left";
    DSIconsLineConfig["CornerDownRight"] = "corner-down-right";
    DSIconsLineConfig["CornerDownRightAlt"] = "corner-down-right-alt";
    DSIconsLineConfig["CornerLeftDown"] = "corner-left-down";
    DSIconsLineConfig["CornerRightDown"] = "corner-right-down";
    DSIconsLineConfig["CornerUpLeft"] = "corner-up-left";
    DSIconsLineConfig["CornerUpLeftAlt"] = "corner-up-left-alt";
    DSIconsLineConfig["CornerUpRight"] = "corner-up-right";
    DSIconsLineConfig["CornerUpRightAlt"] = "corner-up-right-alt";
    DSIconsLineConfig["Coronavirus"] = "coronavirus";
    DSIconsLineConfig["CreateDashboard"] = "create-dashboard";
    DSIconsLineConfig["CreativeCommonsPd"] = "creative-commons-pd";
    DSIconsLineConfig["CreditCard"] = "credit-card";
    DSIconsLineConfig["CreditCardSearch"] = "credit-card-search";
    DSIconsLineConfig["Crockery"] = "crockery";
    DSIconsLineConfig["CropAlt"] = "crop-alt";
    DSIconsLineConfig["CropAltRotateLeft"] = "crop-alt-rotate-left";
    DSIconsLineConfig["CropAltRotateRight"] = "crop-alt-rotate-right";
    DSIconsLineConfig["Crosshair"] = "crosshair";
    DSIconsLineConfig["CrosshairAlt"] = "crosshair-alt";
    DSIconsLineConfig["Crosshairs"] = "crosshairs";
    DSIconsLineConfig["Css3Simple"] = "css3-simple";
    DSIconsLineConfig["Cube"] = "cube";
    DSIconsLineConfig["Dashboard"] = "dashboard";
    DSIconsLineConfig["DataSharing"] = "data-sharing";
    DSIconsLineConfig["Database"] = "database";
    DSIconsLineConfig["DatabaseAlt"] = "database-alt";
    DSIconsLineConfig["Desert"] = "desert";
    DSIconsLineConfig["Desktop"] = "desktop";
    DSIconsLineConfig["DesktopAlt"] = "desktop-alt";
    DSIconsLineConfig["DesktopAltSlash"] = "desktop-alt-slash";
    DSIconsLineConfig["DesktopCloudAlt"] = "desktop-cloud-alt";
    DSIconsLineConfig["DesktopSlash"] = "desktop-slash";
    DSIconsLineConfig["Dialpad"] = "dialpad";
    DSIconsLineConfig["DialpadAlt"] = "dialpad-alt";
    DSIconsLineConfig["Diamond"] = "diamond";
    DSIconsLineConfig["Diary"] = "diary";
    DSIconsLineConfig["DiaryAlt"] = "diary-alt";
    DSIconsLineConfig["DiceFive"] = "dice-five";
    DSIconsLineConfig["DiceFour"] = "dice-four";
    DSIconsLineConfig["DiceOne"] = "dice-one";
    DSIconsLineConfig["DiceSix"] = "dice-six";
    DSIconsLineConfig["DiceThree"] = "dice-three";
    DSIconsLineConfig["DiceTwo"] = "dice-two";
    DSIconsLineConfig["DinersClub"] = "diners-club";
    DSIconsLineConfig["Direction"] = "direction";
    DSIconsLineConfig["Directions"] = "directions";
    DSIconsLineConfig["Discord"] = "discord";
    DSIconsLineConfig["Dislike"] = "dislike";
    DSIconsLineConfig["DizzyMeh"] = "dizzy-meh";
    DSIconsLineConfig["Dna"] = "dna";
    DSIconsLineConfig["Docker"] = "docker";
    DSIconsLineConfig["DocumentInfo"] = "document-info";
    DSIconsLineConfig["DocumentLayoutCenter"] = "document-layout-center";
    DSIconsLineConfig["DocumentLayoutLeft"] = "document-layout-left";
    DSIconsLineConfig["DocumentLayoutRight"] = "document-layout-right";
    DSIconsLineConfig["DollarAlt"] = "dollar-alt";
    DSIconsLineConfig["DollarSign"] = "dollar-sign";
    DSIconsLineConfig["DollarSignAlt"] = "dollar-sign-alt";
    DSIconsLineConfig["DownloadAlt"] = "download-alt";
    DSIconsLineConfig["Draggabledots"] = "draggabledots";
    DSIconsLineConfig["Dribbble"] = "dribbble";
    DSIconsLineConfig["Drill"] = "drill";
    DSIconsLineConfig["Dropbox"] = "dropbox";
    DSIconsLineConfig["Dumbbell"] = "dumbbell";
    DSIconsLineConfig["Ear"] = "ear";
    DSIconsLineConfig["Edit"] = "edit";
    DSIconsLineConfig["EditAlt"] = "edit-alt";
    DSIconsLineConfig["EighteenPlus"] = "eighteen-plus";
    DSIconsLineConfig["ElipsisDoubleVAlt"] = "elipsis-double-v-alt";
    DSIconsLineConfig["EllipsisH"] = "ellipsis-h";
    DSIconsLineConfig["EllipsisV"] = "ellipsis-v";
    DSIconsLineConfig["Elo"] = "elo";
    DSIconsLineConfig["Emoji"] = "emoji";
    DSIconsLineConfig["EnglishToChinese"] = "english-to-chinese";
    DSIconsLineConfig["Enter"] = "enter";
    DSIconsLineConfig["Envelope"] = "envelope";
    DSIconsLineConfig["EnvelopeAdd"] = "envelope-add";
    DSIconsLineConfig["EnvelopeAlt"] = "envelope-alt";
    DSIconsLineConfig["EnvelopeBlock"] = "envelope-block";
    DSIconsLineConfig["EnvelopeBookmark"] = "envelope-bookmark";
    DSIconsLineConfig["EnvelopeCheck"] = "envelope-check";
    DSIconsLineConfig["EnvelopeDownload"] = "envelope-download";
    DSIconsLineConfig["EnvelopeDownloadAlt"] = "envelope-download-alt";
    DSIconsLineConfig["EnvelopeEdit"] = "envelope-edit";
    DSIconsLineConfig["EnvelopeExclamation"] = "envelope-exclamation";
    DSIconsLineConfig["EnvelopeHeart"] = "envelope-heart";
    DSIconsLineConfig["EnvelopeInfo"] = "envelope-info";
    DSIconsLineConfig["EnvelopeLock"] = "envelope-lock";
    DSIconsLineConfig["EnvelopeMinus"] = "envelope-minus";
    DSIconsLineConfig["EnvelopeOpen"] = "envelope-open";
    DSIconsLineConfig["EnvelopeQuestion"] = "envelope-question";
    DSIconsLineConfig["EnvelopeReceive"] = "envelope-receive";
    DSIconsLineConfig["EnvelopeRedo"] = "envelope-redo";
    DSIconsLineConfig["EnvelopeSearch"] = "envelope-search";
    DSIconsLineConfig["EnvelopeSend"] = "envelope-send";
    DSIconsLineConfig["EnvelopeShare"] = "envelope-share";
    DSIconsLineConfig["EnvelopeShield"] = "envelope-shield";
    DSIconsLineConfig["EnvelopeStar"] = "envelope-star";
    DSIconsLineConfig["EnvelopeTimes"] = "envelope-times";
    DSIconsLineConfig["EnvelopeUpload"] = "envelope-upload";
    DSIconsLineConfig["EnvelopeUploadAlt"] = "envelope-upload-alt";
    DSIconsLineConfig["Envelopes"] = "envelopes";
    DSIconsLineConfig["EqualCircle"] = "equal-circle";
    DSIconsLineConfig["Estate"] = "estate";
    DSIconsLineConfig["Euro"] = "euro";
    DSIconsLineConfig["EuroCircle"] = "euro-circle";
    DSIconsLineConfig["Exchange"] = "exchange";
    DSIconsLineConfig["ExchangeAlt"] = "exchange-alt";
    DSIconsLineConfig["Exclamation"] = "exclamation";
    DSIconsLineConfig["ExclamationCircle"] = "exclamation-circle";
    DSIconsLineConfig["ExclamationOctagon"] = "exclamation-octagon";
    DSIconsLineConfig["ExclamationTriangle"] = "exclamation-triangle";
    DSIconsLineConfig["Exclude"] = "exclude";
    DSIconsLineConfig["Exit"] = "exit";
    DSIconsLineConfig["ExpandAlt"] = "expand-alt";
    DSIconsLineConfig["ExpandArrows"] = "expand-arrows";
    DSIconsLineConfig["ExpandArrowsAlt"] = "expand-arrows-alt";
    DSIconsLineConfig["ExpandFromCorner"] = "expand-from-corner";
    DSIconsLineConfig["ExpandLeft"] = "expand-left";
    DSIconsLineConfig["ExpandRight"] = "expand-right";
    DSIconsLineConfig["Export"] = "export";
    DSIconsLineConfig["ExposureAlt"] = "exposure-alt";
    DSIconsLineConfig["ExposureIncrease"] = "exposure-increase";
    DSIconsLineConfig["ExternalLinkAlt"] = "external-link-alt";
    DSIconsLineConfig["Eye"] = "eye";
    DSIconsLineConfig["EyeSlash"] = "eye-slash";
    DSIconsLineConfig["Facebook"] = "facebook";
    DSIconsLineConfig["FacebookF"] = "facebook-f";
    DSIconsLineConfig["FacebookMessenger"] = "facebook-messenger";
    DSIconsLineConfig["FacebookMessengerAlt"] = "facebook-messenger-alt";
    DSIconsLineConfig["Fahrenheit"] = "fahrenheit";
    DSIconsLineConfig["FastMail"] = "fast-mail";
    DSIconsLineConfig["FastMailAlt"] = "fast-mail-alt";
    DSIconsLineConfig["Favorite"] = "favorite";
    DSIconsLineConfig["Feedback"] = "feedback";
    DSIconsLineConfig["FidgetSpinner"] = "fidget-spinner";
    DSIconsLineConfig["File"] = "file";
    DSIconsLineConfig["FileAlt"] = "file-alt";
    DSIconsLineConfig["FileBlank"] = "file-blank";
    DSIconsLineConfig["FileBlockAlt"] = "file-block-alt";
    DSIconsLineConfig["FileBookmarkAlt"] = "file-bookmark-alt";
    DSIconsLineConfig["FileCheck"] = "file-check";
    DSIconsLineConfig["FileCheckAlt"] = "file-check-alt";
    DSIconsLineConfig["FileContract"] = "file-contract";
    DSIconsLineConfig["FileContractDollar"] = "file-contract-dollar";
    DSIconsLineConfig["FileCopyAlt"] = "file-copy-alt";
    DSIconsLineConfig["FileDownload"] = "file-download";
    DSIconsLineConfig["FileDownloadAlt"] = "file-download-alt";
    DSIconsLineConfig["FileEditAlt"] = "file-edit-alt";
    DSIconsLineConfig["FileExclamation"] = "file-exclamation";
    DSIconsLineConfig["FileExclamationAlt"] = "file-exclamation-alt";
    DSIconsLineConfig["FileExport"] = "file-export";
    DSIconsLineConfig["FileGraph"] = "file-graph";
    DSIconsLineConfig["FileHeart"] = "file-heart";
    DSIconsLineConfig["FileImport"] = "file-import";
    DSIconsLineConfig["FileInfoAlt"] = "file-info-alt";
    DSIconsLineConfig["FileLandscape"] = "file-landscape";
    DSIconsLineConfig["FileLandscapeAlt"] = "file-landscape-alt";
    DSIconsLineConfig["FileLanscapeSlash"] = "file-lanscape-slash";
    DSIconsLineConfig["FileLockAlt"] = "file-lock-alt";
    DSIconsLineConfig["FileMedical"] = "file-medical";
    DSIconsLineConfig["FileMedicalAlt"] = "file-medical-alt";
    DSIconsLineConfig["FileMinus"] = "file-minus";
    DSIconsLineConfig["FileMinusAlt"] = "file-minus-alt";
    DSIconsLineConfig["FileNetwork"] = "file-network";
    DSIconsLineConfig["FilePlus"] = "file-plus";
    DSIconsLineConfig["FilePlusAlt"] = "file-plus-alt";
    DSIconsLineConfig["FileQuestion"] = "file-question";
    DSIconsLineConfig["FileQuestionAlt"] = "file-question-alt";
    DSIconsLineConfig["FileRedoAlt"] = "file-redo-alt";
    DSIconsLineConfig["FileSearch"] = "file-search";
    DSIconsLineConfig["FileSearchAlt"] = "file-search-alt";
    DSIconsLineConfig["FileShareAlt"] = "file-share-alt";
    DSIconsLineConfig["FileShieldAlt"] = "file-shield-alt";
    DSIconsLineConfig["FileSlash"] = "file-slash";
    DSIconsLineConfig["FileTimes"] = "file-times";
    DSIconsLineConfig["FileTimesAlt"] = "file-times-alt";
    DSIconsLineConfig["FileUpload"] = "file-upload";
    DSIconsLineConfig["FileUploadAlt"] = "file-upload-alt";
    DSIconsLineConfig["FilesLandscapes"] = "files-landscapes";
    DSIconsLineConfig["FilesLandscapesAlt"] = "files-landscapes-alt";
    DSIconsLineConfig["Film"] = "film";
    DSIconsLineConfig["Filter"] = "filter";
    DSIconsLineConfig["FilterSlash"] = "filter-slash";
    DSIconsLineConfig["Fire"] = "fire";
    DSIconsLineConfig["FivehundredPx"] = "fivehundred-px";
    DSIconsLineConfig["Flask"] = "flask";
    DSIconsLineConfig["FlaskPotion"] = "flask-potion";
    DSIconsLineConfig["FlipH"] = "flip-h";
    DSIconsLineConfig["FlipHAlt"] = "flip-h-alt";
    DSIconsLineConfig["FlipV"] = "flip-v";
    DSIconsLineConfig["FlipVAlt"] = "flip-v-alt";
    DSIconsLineConfig["Flower"] = "flower";
    DSIconsLineConfig["Focus"] = "focus";
    DSIconsLineConfig["FocusAdd"] = "focus-add";
    DSIconsLineConfig["FocusTarget"] = "focus-target";
    DSIconsLineConfig["Folder"] = "folder";
    DSIconsLineConfig["FolderCheck"] = "folder-check";
    DSIconsLineConfig["FolderDownload"] = "folder-download";
    DSIconsLineConfig["FolderExclamation"] = "folder-exclamation";
    DSIconsLineConfig["FolderHeart"] = "folder-heart";
    DSIconsLineConfig["FolderInfo"] = "folder-info";
    DSIconsLineConfig["FolderLock"] = "folder-lock";
    DSIconsLineConfig["FolderMedical"] = "folder-medical";
    DSIconsLineConfig["FolderMinus"] = "folder-minus";
    DSIconsLineConfig["FolderNetwork"] = "folder-network";
    DSIconsLineConfig["FolderOpen"] = "folder-open";
    DSIconsLineConfig["FolderPlus"] = "folder-plus";
    DSIconsLineConfig["FolderQuestion"] = "folder-question";
    DSIconsLineConfig["FolderSlash"] = "folder-slash";
    DSIconsLineConfig["FolderTimes"] = "folder-times";
    DSIconsLineConfig["FolderUpload"] = "folder-upload";
    DSIconsLineConfig["Font"] = "font";
    DSIconsLineConfig["Football"] = "football";
    DSIconsLineConfig["FootballAmerican"] = "football-american";
    DSIconsLineConfig["FootballBall"] = "football-ball";
    DSIconsLineConfig["ForecastcloudMoonTear"] = "forecastcloud-moon-tear";
    DSIconsLineConfig["ForwadedCall"] = "forwaded-call";
    DSIconsLineConfig["Forward"] = "forward";
    DSIconsLineConfig["Frown"] = "frown";
    DSIconsLineConfig["GameStructure"] = "game-structure";
    DSIconsLineConfig["Gift"] = "gift";
    DSIconsLineConfig["Github"] = "github";
    DSIconsLineConfig["GithubAlt"] = "github-alt";
    DSIconsLineConfig["Gitlab"] = "gitlab";
    DSIconsLineConfig["Glass"] = "glass";
    DSIconsLineConfig["GlassMartini"] = "glass-martini";
    DSIconsLineConfig["GlassMartiniAlt"] = "glass-martini-alt";
    DSIconsLineConfig["GlassMartiniAltSlash"] = "glass-martini-alt-slash";
    DSIconsLineConfig["GlassTea"] = "glass-tea";
    DSIconsLineConfig["Globe"] = "globe";
    DSIconsLineConfig["Gold"] = "gold";
    DSIconsLineConfig["GolfBall"] = "golf-ball";
    DSIconsLineConfig["Google"] = "google";
    DSIconsLineConfig["GoogleDrive"] = "google-drive";
    DSIconsLineConfig["GoogleDriveAlt"] = "google-drive-alt";
    DSIconsLineConfig["GoogleHangouts"] = "google-hangouts";
    DSIconsLineConfig["GoogleHangoutsAlt"] = "google-hangouts-alt";
    DSIconsLineConfig["GooglePlay"] = "google-play";
    DSIconsLineConfig["GraduationCap"] = "graduation-cap";
    DSIconsLineConfig["GraphBar"] = "graph-bar";
    DSIconsLineConfig["Grid"] = "grid";
    DSIconsLineConfig["Grids"] = "grids";
    DSIconsLineConfig["Grin"] = "grin";
    DSIconsLineConfig["GrinTongueWink"] = "grin-tongue-wink";
    DSIconsLineConfig["GrinTongueWinkAlt"] = "grin-tongue-wink-alt";
    DSIconsLineConfig["GripHorizontalLine"] = "grip-horizontal-line";
    DSIconsLineConfig["HardHat"] = "hard-hat";
    DSIconsLineConfig["Hdd"] = "hdd";
    DSIconsLineConfig["HeadSide"] = "head-side";
    DSIconsLineConfig["HeadSideCough"] = "head-side-cough";
    DSIconsLineConfig["HeadSideMask"] = "head-side-mask";
    DSIconsLineConfig["HeadphoneSlash"] = "headphone-slash";
    DSIconsLineConfig["Headphones"] = "headphones";
    DSIconsLineConfig["HeadphonesAlt"] = "headphones-alt";
    DSIconsLineConfig["Heart"] = "heart";
    DSIconsLineConfig["HeartAlt"] = "heart-alt";
    DSIconsLineConfig["HeartBreak"] = "heart-break";
    DSIconsLineConfig["HeartMedical"] = "heart-medical";
    DSIconsLineConfig["HeartRate"] = "heart-rate";
    DSIconsLineConfig["HeartSign"] = "heart-sign";
    DSIconsLineConfig["Heartbeat"] = "heartbeat";
    DSIconsLineConfig["HindiToChinese"] = "hindi-to-chinese";
    DSIconsLineConfig["Hipchat"] = "hipchat";
    DSIconsLineConfig["Hipercard"] = "hipercard";
    DSIconsLineConfig["History"] = "history";
    DSIconsLineConfig["HistoryAlt"] = "history-alt";
    DSIconsLineConfig["Home"] = "home";
    DSIconsLineConfig["HomeAlt"] = "home-alt";
    DSIconsLineConfig["HorizontalAlignCenter"] = "horizontal-align-center";
    DSIconsLineConfig["HorizontalAlignLeft"] = "horizontal-align-left";
    DSIconsLineConfig["HorizontalAlignRight"] = "horizontal-align-right";
    DSIconsLineConfig["HorizontalDistributionCenter"] = "horizontal-distribution-center";
    DSIconsLineConfig["HorizontalDistributionLeft"] = "horizontal-distribution-left";
    DSIconsLineConfig["HorizontalDistributionRight"] = "horizontal-distribution-right";
    DSIconsLineConfig["Hospital"] = "hospital";
    DSIconsLineConfig["HospitalSquareSign"] = "hospital-square-sign";
    DSIconsLineConfig["HospitalSymbol"] = "hospital-symbol";
    DSIconsLineConfig["Hourglass"] = "hourglass";
    DSIconsLineConfig["HouseUser"] = "house-user";
    DSIconsLineConfig["Html3"] = "html3";
    DSIconsLineConfig["Html3Alt"] = "html3-alt";
    DSIconsLineConfig["Html5"] = "html5";
    DSIconsLineConfig["Html5Alt"] = "html5-alt";
    DSIconsLineConfig["Hunting"] = "hunting";
    DSIconsLineConfig["Icons"] = "icons";
    DSIconsLineConfig["Illustration"] = "illustration";
    DSIconsLineConfig["Image"] = "image";
    DSIconsLineConfig["ImageAltSlash"] = "image-alt-slash";
    DSIconsLineConfig["ImageBlock"] = "image-block";
    DSIconsLineConfig["ImageBroken"] = "image-broken";
    DSIconsLineConfig["ImageCheck"] = "image-check";
    DSIconsLineConfig["ImageDownload"] = "image-download";
    DSIconsLineConfig["ImageEdit"] = "image-edit";
    DSIconsLineConfig["ImageLock"] = "image-lock";
    DSIconsLineConfig["ImageMinus"] = "image-minus";
    DSIconsLineConfig["ImagePlus"] = "image-plus";
    DSIconsLineConfig["ImageQuestion"] = "image-question";
    DSIconsLineConfig["ImageRedo"] = "image-redo";
    DSIconsLineConfig["ImageResizeLandscape"] = "image-resize-landscape";
    DSIconsLineConfig["ImageResizeSquare"] = "image-resize-square";
    DSIconsLineConfig["ImageSearch"] = "image-search";
    DSIconsLineConfig["ImageShare"] = "image-share";
    DSIconsLineConfig["ImageShield"] = "image-shield";
    DSIconsLineConfig["ImageSlash"] = "image-slash";
    DSIconsLineConfig["ImageTimes"] = "image-times";
    DSIconsLineConfig["ImageUpload"] = "image-upload";
    DSIconsLineConfig["ImageV"] = "image-v";
    DSIconsLineConfig["Images"] = "images";
    DSIconsLineConfig["ImportAction"] = "import-action";
    DSIconsLineConfig["Inbox"] = "inbox";
    DSIconsLineConfig["IncomingCall"] = "incoming-call";
    DSIconsLineConfig["Info"] = "info";
    DSIconsLineConfig["InfoCircle"] = "info-circle";
    DSIconsLineConfig["Instagram"] = "instagram";
    DSIconsLineConfig["InstagramAlt"] = "instagram-alt";
    DSIconsLineConfig["Intercom"] = "intercom";
    DSIconsLineConfig["IntercomAlt"] = "intercom-alt";
    DSIconsLineConfig["Invoice"] = "invoice";
    DSIconsLineConfig["Italic"] = "italic";
    DSIconsLineConfig["Jackhammer"] = "jackhammer";
    DSIconsLineConfig["JavaScript"] = "java-script";
    DSIconsLineConfig["Kayak"] = "kayak";
    DSIconsLineConfig["KeySkeleton"] = "key-skeleton";
    DSIconsLineConfig["KeySkeletonAlt"] = "key-skeleton-alt";
    DSIconsLineConfig["Keyboard"] = "keyboard";
    DSIconsLineConfig["KeyboardAlt"] = "keyboard-alt";
    DSIconsLineConfig["KeyboardHide"] = "keyboard-hide";
    DSIconsLineConfig["KeyboardShow"] = "keyboard-show";
    DSIconsLineConfig["KeyholeCircle"] = "keyhole-circle";
    DSIconsLineConfig["KeyholeSquare"] = "keyhole-square";
    DSIconsLineConfig["KeyholeSquareFull"] = "keyhole-square-full";
    DSIconsLineConfig["Keylines"] = "keylines";
    DSIconsLineConfig["Kid"] = "kid";
    DSIconsLineConfig["Label"] = "label";
    DSIconsLineConfig["LabelAlt"] = "label-alt";
    DSIconsLineConfig["Lamp"] = "lamp";
    DSIconsLineConfig["Language"] = "language";
    DSIconsLineConfig["Laptop"] = "laptop";
    DSIconsLineConfig["LaptopCloud"] = "laptop-cloud";
    DSIconsLineConfig["LaptopConnection"] = "laptop-connection";
    DSIconsLineConfig["Laughing"] = "laughing";
    DSIconsLineConfig["LayerGroup"] = "layer-group";
    DSIconsLineConfig["LayerGroupSlash"] = "layer-group-slash";
    DSIconsLineConfig["Layers"] = "layers";
    DSIconsLineConfig["LayersAlt"] = "layers-alt";
    DSIconsLineConfig["LayersSlash"] = "layers-slash";
    DSIconsLineConfig["LeftArrowFromLeft"] = "left-arrow-from-left";
    DSIconsLineConfig["LeftArrowToLeft"] = "left-arrow-to-left";
    DSIconsLineConfig["LeftIndent"] = "left-indent";
    DSIconsLineConfig["LeftIndentAlt"] = "left-indent-alt";
    DSIconsLineConfig["LeftToRightTextDirection"] = "left-to-right-text-direction";
    DSIconsLineConfig["LetterChineseA"] = "letter-chinese-a";
    DSIconsLineConfig["LetterEnglishA"] = "letter-english-a";
    DSIconsLineConfig["LetterHindiA"] = "letter-hindi-a";
    DSIconsLineConfig["LetterJapaneseA"] = "letter-japanese-a";
    DSIconsLineConfig["LifeRing"] = "life-ring";
    DSIconsLineConfig["Lightbulb"] = "lightbulb";
    DSIconsLineConfig["LightbulbAlt"] = "lightbulb-alt";
    DSIconsLineConfig["Like"] = "like";
    DSIconsLineConfig["Line"] = "line";
    DSIconsLineConfig["LineAlt"] = "line-alt";
    DSIconsLineConfig["LineSpacing"] = "line-spacing";
    DSIconsLineConfig["Link"] = "link";
    DSIconsLineConfig["LinkAdd"] = "link-add";
    DSIconsLineConfig["LinkAlt"] = "link-alt";
    DSIconsLineConfig["LinkBroken"] = "link-broken";
    DSIconsLineConfig["LinkH"] = "link-h";
    DSIconsLineConfig["Linkedin"] = "linkedin";
    DSIconsLineConfig["LinkedinAlt"] = "linkedin-alt";
    DSIconsLineConfig["Linux"] = "linux";
    DSIconsLineConfig["LiraSign"] = "lira-sign";
    DSIconsLineConfig["ListOl"] = "list-ol";
    DSIconsLineConfig["ListUiAlt"] = "list-ui-alt";
    DSIconsLineConfig["ListUl"] = "list-ul";
    DSIconsLineConfig["Load"] = "load";
    DSIconsLineConfig["LocationArrow"] = "location-arrow";
    DSIconsLineConfig["LocationArrowAlt"] = "location-arrow-alt";
    DSIconsLineConfig["LocationPinAlt"] = "location-pin-alt";
    DSIconsLineConfig["LocationPoint"] = "location-point";
    DSIconsLineConfig["Lock"] = "lock";
    DSIconsLineConfig["LockAccess"] = "lock-access";
    DSIconsLineConfig["LockAlt"] = "lock-alt";
    DSIconsLineConfig["LockOpenAlt"] = "lock-open-alt";
    DSIconsLineConfig["LockSlash"] = "lock-slash";
    DSIconsLineConfig["Lottiefiles"] = "lottiefiles";
    DSIconsLineConfig["LottiefilesAlt"] = "lottiefiles-alt";
    DSIconsLineConfig["LuggageCart"] = "luggage-cart";
    DSIconsLineConfig["Mailbox"] = "mailbox";
    DSIconsLineConfig["MailboxAlt"] = "mailbox-alt";
    DSIconsLineConfig["Map"] = "map";
    DSIconsLineConfig["MapMarker"] = "map-marker";
    DSIconsLineConfig["MapMarkerAlt"] = "map-marker-alt";
    DSIconsLineConfig["MapMarkerEdit"] = "map-marker-edit";
    DSIconsLineConfig["MapMarkerInfo"] = "map-marker-info";
    DSIconsLineConfig["MapMarkerMinus"] = "map-marker-minus";
    DSIconsLineConfig["MapMarkerPlus"] = "map-marker-plus";
    DSIconsLineConfig["MapMarkerQuestion"] = "map-marker-question";
    DSIconsLineConfig["MapMarkerShield"] = "map-marker-shield";
    DSIconsLineConfig["MapMarkerSlash"] = "map-marker-slash";
    DSIconsLineConfig["MapPin"] = "map-pin";
    DSIconsLineConfig["MapPinAlt"] = "map-pin-alt";
    DSIconsLineConfig["Mars"] = "mars";
    DSIconsLineConfig["MasterCard"] = "master-card";
    DSIconsLineConfig["Mastercard"] = "mastercard";
    DSIconsLineConfig["MaximizeLeft"] = "maximize-left";
    DSIconsLineConfig["Medal"] = "medal";
    DSIconsLineConfig["MedicalDrip"] = "medical-drip";
    DSIconsLineConfig["MedicalSquare"] = "medical-square";
    DSIconsLineConfig["MedicalSquareFull"] = "medical-square-full";
    DSIconsLineConfig["MediumM"] = "medium-m";
    DSIconsLineConfig["Medkit"] = "medkit";
    DSIconsLineConfig["MeetingBoard"] = "meeting-board";
    DSIconsLineConfig["Megaphone"] = "megaphone";
    DSIconsLineConfig["Meh"] = "meh";
    DSIconsLineConfig["MehAlt"] = "meh-alt";
    DSIconsLineConfig["MehClosedEye"] = "meh-closed-eye";
    DSIconsLineConfig["Message"] = "message";
    DSIconsLineConfig["Metro"] = "metro";
    DSIconsLineConfig["Microphone"] = "microphone";
    DSIconsLineConfig["MicrophoneSlash"] = "microphone-slash";
    DSIconsLineConfig["Microscope"] = "microscope";
    DSIconsLineConfig["Microsoft"] = "microsoft";
    DSIconsLineConfig["Minus"] = "minus";
    DSIconsLineConfig["MinusCircle"] = "minus-circle";
    DSIconsLineConfig["MinusPath"] = "minus-path";
    DSIconsLineConfig["MinusSquare"] = "minus-square";
    DSIconsLineConfig["MinusSquareFull"] = "minus-square-full";
    DSIconsLineConfig["MissedCall"] = "missed-call";
    DSIconsLineConfig["MobileAndroid"] = "mobile-android";
    DSIconsLineConfig["MobileAndroidAlt"] = "mobile-android-alt";
    DSIconsLineConfig["MobileVibrate"] = "mobile-vibrate";
    DSIconsLineConfig["Modem"] = "modem";
    DSIconsLineConfig["MoneyBill"] = "money-bill";
    DSIconsLineConfig["MoneyBillSlash"] = "money-bill-slash";
    DSIconsLineConfig["MoneyBillStack"] = "money-bill-stack";
    DSIconsLineConfig["MoneyInsert"] = "money-insert";
    DSIconsLineConfig["MoneyStack"] = "money-stack";
    DSIconsLineConfig["MoneyWithdraw"] = "money-withdraw";
    DSIconsLineConfig["MoneyWithdrawal"] = "money-withdrawal";
    DSIconsLineConfig["Moneybag"] = "moneybag";
    DSIconsLineConfig["MoneybagAlt"] = "moneybag-alt";
    DSIconsLineConfig["Monitor"] = "monitor";
    DSIconsLineConfig["MonitorHeartRate"] = "monitor-heart-rate";
    DSIconsLineConfig["Moon"] = "moon";
    DSIconsLineConfig["MoonEclipse"] = "moon-eclipse";
    DSIconsLineConfig["Moonset"] = "moonset";
    DSIconsLineConfig["Mountains"] = "mountains";
    DSIconsLineConfig["MountainsSun"] = "mountains-sun";
    DSIconsLineConfig["Mouse"] = "mouse";
    DSIconsLineConfig["MouseAlt"] = "mouse-alt";
    DSIconsLineConfig["MouseAlt2"] = "mouse-alt-2";
    DSIconsLineConfig["Multiply"] = "multiply";
    DSIconsLineConfig["Music"] = "music";
    DSIconsLineConfig["MusicNote"] = "music-note";
    DSIconsLineConfig["MusicTuneSlash"] = "music-tune-slash";
    DSIconsLineConfig["NA"] = "n-a";
    DSIconsLineConfig["Navigator"] = "navigator";
    DSIconsLineConfig["Nerd"] = "nerd";
    DSIconsLineConfig["Newspaper"] = "newspaper";
    DSIconsLineConfig["Ninja"] = "ninja";
    DSIconsLineConfig["NoEntry"] = "no-entry";
    DSIconsLineConfig["Notebooks"] = "notebooks";
    DSIconsLineConfig["Notes"] = "notes";
    DSIconsLineConfig["ObjectGroup"] = "object-group";
    DSIconsLineConfig["ObjectUngroup"] = "object-ungroup";
    DSIconsLineConfig["Octagon"] = "octagon";
    DSIconsLineConfig["Okta"] = "okta";
    DSIconsLineConfig["Opera"] = "opera";
    DSIconsLineConfig["OperaAlt"] = "opera-alt";
    DSIconsLineConfig["OutgoingCall"] = "outgoing-call";
    DSIconsLineConfig["Packet"] = "packet";
    DSIconsLineConfig["Padlock"] = "padlock";
    DSIconsLineConfig["Pagelines"] = "pagelines";
    DSIconsLineConfig["Pagerduty"] = "pagerduty";
    DSIconsLineConfig["PaintTool"] = "paint-tool";
    DSIconsLineConfig["Palette"] = "palette";
    DSIconsLineConfig["PanelAdd"] = "panel-add";
    DSIconsLineConfig["PanoramaH"] = "panorama-h";
    DSIconsLineConfig["PanoramaHAlt"] = "panorama-h-alt";
    DSIconsLineConfig["PanoramaV"] = "panorama-v";
    DSIconsLineConfig["Paperclip"] = "paperclip";
    DSIconsLineConfig["Paragraph"] = "paragraph";
    DSIconsLineConfig["Parcel"] = "parcel";
    DSIconsLineConfig["ParkingCircle"] = "parking-circle";
    DSIconsLineConfig["ParkingSquare"] = "parking-square";
    DSIconsLineConfig["Pathfinder"] = "pathfinder";
    DSIconsLineConfig["PathfinderUnite"] = "pathfinder-unite";
    DSIconsLineConfig["Pause"] = "pause";
    DSIconsLineConfig["PauseCircle"] = "pause-circle";
    DSIconsLineConfig["Paypal"] = "paypal";
    DSIconsLineConfig["Pen"] = "pen";
    DSIconsLineConfig["Pentagon"] = "pentagon";
    DSIconsLineConfig["Percent"] = "percent";
    DSIconsLineConfig["Percentage"] = "percentage";
    DSIconsLineConfig["PetzArgan"] = "petz-argan";
    DSIconsLineConfig["PetzBag"] = "petz-bag";
    DSIconsLineConfig["PetzBanhoETosa"] = "petz-banho-e-tosa";
    DSIconsLineConfig["PetzBarcode"] = "petz-barcode";
    DSIconsLineConfig["PetzBath"] = "petz-bath";
    DSIconsLineConfig["PetzCalendar"] = "petz-calendar";
    DSIconsLineConfig["PetzCancel"] = "petz-cancel";
    DSIconsLineConfig["PetzCard"] = "petz-card";
    DSIconsLineConfig["PetzChevronDown"] = "petz-chevron-down";
    DSIconsLineConfig["PetzChevronLeft"] = "petz-chevron-left";
    DSIconsLineConfig["PetzChevronRight"] = "petz-chevron-right";
    DSIconsLineConfig["PetzChevronUp"] = "petz-chevron-up";
    DSIconsLineConfig["PetzClubz"] = "petz-clubz";
    DSIconsLineConfig["PetzCoupon"] = "petz-coupon";
    DSIconsLineConfig["PetzDelivery"] = "petz-delivery";
    DSIconsLineConfig["PetzDiscount"] = "petz-discount";
    DSIconsLineConfig["PetzEscape"] = "petz-escape";
    DSIconsLineConfig["PetzFaq"] = "petz-faq";
    DSIconsLineConfig["PetzFavorite"] = "petz-favorite";
    DSIconsLineConfig["PetzFilter"] = "petz-filter";
    DSIconsLineConfig["PetzFunnel"] = "petz-funnel";
    DSIconsLineConfig["PetzFurminator"] = "petz-furminator";
    DSIconsLineConfig["PetzGrooming"] = "petz-grooming";
    DSIconsLineConfig["PetzHydra"] = "petz-hydra";
    DSIconsLineConfig["PetzLocation"] = "petz-location";
    DSIconsLineConfig["PetzMapMarker"] = "petz-map-marker";
    DSIconsLineConfig["PetzMenu"] = "petz-menu";
    DSIconsLineConfig["PetzMinus"] = "petz-minus";
    DSIconsLineConfig["PetzMyPet"] = "petz-my-pet";
    DSIconsLineConfig["PetzNotification"] = "petz-notification";
    DSIconsLineConfig["PetzOrder"] = "petz-order";
    DSIconsLineConfig["PetzPackage"] = "petz-package";
    DSIconsLineConfig["PetzPix"] = "petz-pix";
    DSIconsLineConfig["PetzProfile"] = "petz-profile";
    DSIconsLineConfig["PetzRebuy"] = "petz-rebuy";
    DSIconsLineConfig["PetzRecompra"] = "petz-recompra";
    DSIconsLineConfig["PetzRefresh"] = "petz-refresh";
    DSIconsLineConfig["PetzScissors"] = "petz-scissors";
    DSIconsLineConfig["PetzSearch"] = "petz-search";
    DSIconsLineConfig["PetzShaver"] = "petz-shaver";
    DSIconsLineConfig["PetzShop"] = "petz-shop";
    DSIconsLineConfig["PetzStripping"] = "petz-stripping";
    DSIconsLineConfig["PetzSubscription"] = "petz-subscription";
    DSIconsLineConfig["PetzToning"] = "petz-toning";
    DSIconsLineConfig["PetzTrash"] = "petz-trash";
    DSIconsLineConfig["PetzTune"] = "petz-tune";
    DSIconsLineConfig["PetzVetCardio"] = "petz-vet-cardio";
    DSIconsLineConfig["PetzVetKit"] = "petz-vet-kit";
    DSIconsLineConfig["PetzVetPhone"] = "petz-vet-phone";
    DSIconsLineConfig["Phone"] = "phone";
    DSIconsLineConfig["PhoneAlt"] = "phone-alt";
    DSIconsLineConfig["PhonePause"] = "phone-pause";
    DSIconsLineConfig["PhoneSlash"] = "phone-slash";
    DSIconsLineConfig["PhoneTimes"] = "phone-times";
    DSIconsLineConfig["PhoneVolume"] = "phone-volume";
    DSIconsLineConfig["Picture"] = "picture";
    DSIconsLineConfig["Pinterest"] = "pinterest";
    DSIconsLineConfig["PizzaSlice"] = "pizza-slice";
    DSIconsLineConfig["Plane"] = "plane";
    DSIconsLineConfig["PlaneArrival"] = "plane-arrival";
    DSIconsLineConfig["PlaneDeparture"] = "plane-departure";
    DSIconsLineConfig["PlaneFly"] = "plane-fly";
    DSIconsLineConfig["Play"] = "play";
    DSIconsLineConfig["PlayCircle"] = "play-circle";
    DSIconsLineConfig["Plug"] = "plug";
    DSIconsLineConfig["Plus"] = "plus";
    DSIconsLineConfig["PlusCircle"] = "plus-circle";
    DSIconsLineConfig["PlusSquare"] = "plus-square";
    DSIconsLineConfig["Podium"] = "podium";
    DSIconsLineConfig["Polygon"] = "polygon";
    DSIconsLineConfig["PostStamp"] = "post-stamp";
    DSIconsLineConfig["Postcard"] = "postcard";
    DSIconsLineConfig["Pound"] = "pound";
    DSIconsLineConfig["PoundCircle"] = "pound-circle";
    DSIconsLineConfig["Power"] = "power";
    DSIconsLineConfig["PrescriptionBottle"] = "prescription-bottle";
    DSIconsLineConfig["Presentation"] = "presentation";
    DSIconsLineConfig["PresentationCheck"] = "presentation-check";
    DSIconsLineConfig["PresentationEdit"] = "presentation-edit";
    DSIconsLineConfig["PresentationLine"] = "presentation-line";
    DSIconsLineConfig["PresentationLinesAlt"] = "presentation-lines-alt";
    DSIconsLineConfig["PresentationMinus"] = "presentation-minus";
    DSIconsLineConfig["PresentationPlay"] = "presentation-play";
    DSIconsLineConfig["PresentationPlus"] = "presentation-plus";
    DSIconsLineConfig["PresentationTimes"] = "presentation-times";
    DSIconsLineConfig["Previous"] = "previous";
    DSIconsLineConfig["PricetagAlt"] = "pricetag-alt";
    DSIconsLineConfig["Print"] = "print";
    DSIconsLineConfig["PrintSlash"] = "print-slash";
    DSIconsLineConfig["Process"] = "process";
    DSIconsLineConfig["Processor"] = "processor";
    DSIconsLineConfig["ProgrammingLanguage"] = "programming-language";
    DSIconsLineConfig["Pump"] = "pump";
    DSIconsLineConfig["PuzzlePiece"] = "puzzle-piece";
    DSIconsLineConfig["QrcodeScan"] = "qrcode-scan";
    DSIconsLineConfig["Question"] = "question";
    DSIconsLineConfig["QuestionCircle"] = "question-circle";
    DSIconsLineConfig["Rainbow"] = "rainbow";
    DSIconsLineConfig["Raindrops"] = "raindrops";
    DSIconsLineConfig["RaindropsAlt"] = "raindrops-alt";
    DSIconsLineConfig["React"] = "react";
    DSIconsLineConfig["Receipt"] = "receipt";
    DSIconsLineConfig["ReceiptAlt"] = "receipt-alt";
    DSIconsLineConfig["RecordAudio"] = "record-audio";
    DSIconsLineConfig["RedditAlienAlt"] = "reddit-alien-alt";
    DSIconsLineConfig["Redo"] = "redo";
    DSIconsLineConfig["Refresh"] = "refresh";
    DSIconsLineConfig["Registered"] = "registered";
    DSIconsLineConfig["Repeat"] = "repeat";
    DSIconsLineConfig["Restaurant"] = "restaurant";
    DSIconsLineConfig["RightIndentAlt"] = "right-indent-alt";
    DSIconsLineConfig["RightToLeftTextDirection"] = "right-to-left-text-direction";
    DSIconsLineConfig["Robot"] = "robot";
    DSIconsLineConfig["Rocket"] = "rocket";
    DSIconsLineConfig["RopeWay"] = "rope-way";
    DSIconsLineConfig["Rotate360"] = "rotate-360";
    DSIconsLineConfig["RotateScreen"] = "rotate-screen";
    DSIconsLineConfig["Rss"] = "rss";
    DSIconsLineConfig["RssAlt"] = "rss-alt";
    DSIconsLineConfig["RssInterface"] = "rss-interface";
    DSIconsLineConfig["Ruler"] = "ruler";
    DSIconsLineConfig["RulerCombined"] = "ruler-combined";
    DSIconsLineConfig["RupeeSign"] = "rupee-sign";
    DSIconsLineConfig["Sad"] = "sad";
    DSIconsLineConfig["SadCry"] = "sad-cry";
    DSIconsLineConfig["SadCrying"] = "sad-crying";
    DSIconsLineConfig["SadDizzy"] = "sad-dizzy";
    DSIconsLineConfig["SadSquint"] = "sad-squint";
    DSIconsLineConfig["Sanitizer"] = "sanitizer";
    DSIconsLineConfig["SanitizerAlt"] = "sanitizer-alt";
    DSIconsLineConfig["Save"] = "save";
    DSIconsLineConfig["ScalingLeft"] = "scaling-left";
    DSIconsLineConfig["ScalingRight"] = "scaling-right";
    DSIconsLineConfig["Scenery"] = "scenery";
    DSIconsLineConfig["Schedule"] = "schedule";
    DSIconsLineConfig["Screw"] = "screw";
    DSIconsLineConfig["Scroll"] = "scroll";
    DSIconsLineConfig["ScrollH"] = "scroll-h";
    DSIconsLineConfig["Search"] = "search";
    DSIconsLineConfig["SearchAlt"] = "search-alt";
    DSIconsLineConfig["SearchMinus"] = "search-minus";
    DSIconsLineConfig["SearchPlus"] = "search-plus";
    DSIconsLineConfig["Selfie"] = "selfie";
    DSIconsLineConfig["Server"] = "server";
    DSIconsLineConfig["ServerAlt"] = "server-alt";
    DSIconsLineConfig["ServerConnection"] = "server-connection";
    DSIconsLineConfig["ServerNetwork"] = "server-network";
    DSIconsLineConfig["ServerNetworkAlt"] = "server-network-alt";
    DSIconsLineConfig["Servers"] = "servers";
    DSIconsLineConfig["Servicemark"] = "servicemark";
    DSIconsLineConfig["Setting"] = "setting";
    DSIconsLineConfig["SeventeenPlus"] = "seventeen-plus";
    DSIconsLineConfig["Share"] = "share";
    DSIconsLineConfig["ShareAlt"] = "share-alt";
    DSIconsLineConfig["Shelf"] = "shelf";
    DSIconsLineConfig["Shield"] = "shield";
    DSIconsLineConfig["ShieldCheck"] = "shield-check";
    DSIconsLineConfig["ShieldExclamation"] = "shield-exclamation";
    DSIconsLineConfig["ShieldPlus"] = "shield-plus";
    DSIconsLineConfig["ShieldQuestion"] = "shield-question";
    DSIconsLineConfig["ShieldSlash"] = "shield-slash";
    DSIconsLineConfig["Ship"] = "ship";
    DSIconsLineConfig["Shop"] = "shop";
    DSIconsLineConfig["ShoppingBag"] = "shopping-bag";
    DSIconsLineConfig["ShoppingBasket"] = "shopping-basket";
    DSIconsLineConfig["ShoppingCart"] = "shopping-cart";
    DSIconsLineConfig["ShoppingCartAlt"] = "shopping-cart-alt";
    DSIconsLineConfig["Shovel"] = "shovel";
    DSIconsLineConfig["Shrink"] = "shrink";
    DSIconsLineConfig["Shuffle"] = "shuffle";
    DSIconsLineConfig["Shutter"] = "shutter";
    DSIconsLineConfig["ShutterAlt"] = "shutter-alt";
    DSIconsLineConfig["Sick"] = "sick";
    DSIconsLineConfig["SideMenu"] = "side-menu";
    DSIconsLineConfig["Sigma"] = "sigma";
    DSIconsLineConfig["SignAlt"] = "sign-alt";
    DSIconsLineConfig["SignInAlt"] = "sign-in-alt";
    DSIconsLineConfig["SignLeft"] = "sign-left";
    DSIconsLineConfig["SignOutAlt"] = "sign-out-alt";
    DSIconsLineConfig["SignRight"] = "sign-right";
    DSIconsLineConfig["Signal"] = "signal";
    DSIconsLineConfig["SignalAlt"] = "signal-alt";
    DSIconsLineConfig["SignalAlt3"] = "signal-alt-3";
    DSIconsLineConfig["Signin"] = "signin";
    DSIconsLineConfig["Signout"] = "signout";
    DSIconsLineConfig["Silence"] = "silence";
    DSIconsLineConfig["SilentSquint"] = "silent-squint";
    DSIconsLineConfig["SimCard"] = "sim-card";
    DSIconsLineConfig["Sitemap"] = "sitemap";
    DSIconsLineConfig["SixPlus"] = "six-plus";
    DSIconsLineConfig["SixteenPlus"] = "sixteen-plus";
    DSIconsLineConfig["SkipForward"] = "skip-forward";
    DSIconsLineConfig["SkipForwardAlt"] = "skip-forward-alt";
    DSIconsLineConfig["SkipForwardCircle"] = "skip-forward-circle";
    DSIconsLineConfig["Skype"] = "skype";
    DSIconsLineConfig["SkypeAlt"] = "skype-alt";
    DSIconsLineConfig["Slack"] = "slack";
    DSIconsLineConfig["SlackAlt"] = "slack-alt";
    DSIconsLineConfig["SliderH"] = "slider-h";
    DSIconsLineConfig["SliderHRange"] = "slider-h-range";
    DSIconsLineConfig["SlidersV"] = "sliders-v";
    DSIconsLineConfig["SlidersVAlt"] = "sliders-v-alt";
    DSIconsLineConfig["Smile"] = "smile";
    DSIconsLineConfig["SmileBeam"] = "smile-beam";
    DSIconsLineConfig["SmileDizzy"] = "smile-dizzy";
    DSIconsLineConfig["SmileSquintWink"] = "smile-squint-wink";
    DSIconsLineConfig["SmileSquintWinkAlt"] = "smile-squint-wink-alt";
    DSIconsLineConfig["SmileWink"] = "smile-wink";
    DSIconsLineConfig["SmileWinkAlt"] = "smile-wink-alt";
    DSIconsLineConfig["SnapchatAlt"] = "snapchat-alt";
    DSIconsLineConfig["SnapchatGhost"] = "snapchat-ghost";
    DSIconsLineConfig["SnapchatSquare"] = "snapchat-square";
    DSIconsLineConfig["SnowFlake"] = "snow-flake";
    DSIconsLineConfig["Snowflake"] = "snowflake";
    DSIconsLineConfig["SnowflakeAlt"] = "snowflake-alt";
    DSIconsLineConfig["SocialDistancing"] = "social-distancing";
    DSIconsLineConfig["Sort"] = "sort";
    DSIconsLineConfig["SortAmountDown"] = "sort-amount-down";
    DSIconsLineConfig["SortAmountUp"] = "sort-amount-up";
    DSIconsLineConfig["Sorting"] = "sorting";
    DSIconsLineConfig["SpaceKey"] = "space-key";
    DSIconsLineConfig["Spade"] = "spade";
    DSIconsLineConfig["Sperms"] = "sperms";
    DSIconsLineConfig["Spin"] = "spin";
    DSIconsLineConfig["Spinner"] = "spinner";
    DSIconsLineConfig["SpinnerAlt"] = "spinner-alt";
    DSIconsLineConfig["Square"] = "square";
    DSIconsLineConfig["SquareFull"] = "square-full";
    DSIconsLineConfig["SquareShape"] = "square-shape";
    DSIconsLineConfig["Squint"] = "squint";
    DSIconsLineConfig["Star"] = "star";
    DSIconsLineConfig["StarHalfAlt"] = "star-half-alt";
    DSIconsLineConfig["StepBackward"] = "step-backward";
    DSIconsLineConfig["StepBackwardAlt"] = "step-backward-alt";
    DSIconsLineConfig["StepBackwardCircle"] = "step-backward-circle";
    DSIconsLineConfig["StepForward"] = "step-forward";
    DSIconsLineConfig["Stethoscope"] = "stethoscope";
    DSIconsLineConfig["StethoscopeAlt"] = "stethoscope-alt";
    DSIconsLineConfig["StopCircle"] = "stop-circle";
    DSIconsLineConfig["Stopwatch"] = "stopwatch";
    DSIconsLineConfig["StopwatchSlash"] = "stopwatch-slash";
    DSIconsLineConfig["Store"] = "store";
    DSIconsLineConfig["StoreAlt"] = "store-alt";
    DSIconsLineConfig["StoreSlash"] = "store-slash";
    DSIconsLineConfig["Streering"] = "streering";
    DSIconsLineConfig["Stretcher"] = "stretcher";
    DSIconsLineConfig["Subject"] = "subject";
    DSIconsLineConfig["Subway"] = "subway";
    DSIconsLineConfig["SubwayAlt"] = "subway-alt";
    DSIconsLineConfig["Suitcase"] = "suitcase";
    DSIconsLineConfig["SuitcaseAlt"] = "suitcase-alt";
    DSIconsLineConfig["Sun"] = "sun";
    DSIconsLineConfig["Sunset"] = "sunset";
    DSIconsLineConfig["Surprise"] = "surprise";
    DSIconsLineConfig["Swatchbook"] = "swatchbook";
    DSIconsLineConfig["Swiggy"] = "swiggy";
    DSIconsLineConfig["Swimmer"] = "swimmer";
    DSIconsLineConfig["Sync"] = "sync";
    DSIconsLineConfig["SyncExclamation"] = "sync-exclamation";
    DSIconsLineConfig["SyncSlash"] = "sync-slash";
    DSIconsLineConfig["Syringe"] = "syringe";
    DSIconsLineConfig["Table"] = "table";
    DSIconsLineConfig["TableTennis"] = "table-tennis";
    DSIconsLineConfig["Tablet"] = "tablet";
    DSIconsLineConfig["Tablets"] = "tablets";
    DSIconsLineConfig["TachometerFast"] = "tachometer-fast";
    DSIconsLineConfig["TachometerFastAlt"] = "tachometer-fast-alt";
    DSIconsLineConfig["Tag"] = "tag";
    DSIconsLineConfig["TagAlt"] = "tag-alt";
    DSIconsLineConfig["Tape"] = "tape";
    DSIconsLineConfig["Taxi"] = "taxi";
    DSIconsLineConfig["Tear"] = "tear";
    DSIconsLineConfig["Telegram"] = "telegram";
    DSIconsLineConfig["TelegramAlt"] = "telegram-alt";
    DSIconsLineConfig["Telescope"] = "telescope";
    DSIconsLineConfig["Temperature"] = "temperature";
    DSIconsLineConfig["TemperatureEmpty"] = "temperature-empty";
    DSIconsLineConfig["TemperatureHalf"] = "temperature-half";
    DSIconsLineConfig["TemperatureMinus"] = "temperature-minus";
    DSIconsLineConfig["TemperaturePlus"] = "temperature-plus";
    DSIconsLineConfig["TemperatureQuarter"] = "temperature-quarter";
    DSIconsLineConfig["TemperatureThreeQuarter"] = "temperature-three-quarter";
    DSIconsLineConfig["TenPlus"] = "ten-plus";
    DSIconsLineConfig["TennisBall"] = "tennis-ball";
    DSIconsLineConfig["Text"] = "text";
    DSIconsLineConfig["TextFields"] = "text-fields";
    DSIconsLineConfig["TextSize"] = "text-size";
    DSIconsLineConfig["TextStrikeThrough"] = "text-strike-through";
    DSIconsLineConfig["Th"] = "th";
    DSIconsLineConfig["ThLarge"] = "th-large";
    DSIconsLineConfig["ThSlash"] = "th-slash";
    DSIconsLineConfig["Thermometer"] = "thermometer";
    DSIconsLineConfig["ThirteenPlus"] = "thirteen-plus";
    DSIconsLineConfig["ThreePlus"] = "three-plus";
    DSIconsLineConfig["ThumbsDown"] = "thumbs-down";
    DSIconsLineConfig["ThumbsUp"] = "thumbs-up";
    DSIconsLineConfig["Thunderstorm"] = "thunderstorm";
    DSIconsLineConfig["ThunderstormMoon"] = "thunderstorm-moon";
    DSIconsLineConfig["ThunderstormSun"] = "thunderstorm-sun";
    DSIconsLineConfig["Ticket"] = "ticket";
    DSIconsLineConfig["Tiktok"] = "tiktok";
    DSIconsLineConfig["Times"] = "times";
    DSIconsLineConfig["TimesCircle"] = "times-circle";
    DSIconsLineConfig["TimesSquare"] = "times-square";
    DSIconsLineConfig["ToggleOff"] = "toggle-off";
    DSIconsLineConfig["ToggleOn"] = "toggle-on";
    DSIconsLineConfig["ToiletPaper"] = "toilet-paper";
    DSIconsLineConfig["TopArrowFromTop"] = "top-arrow-from-top";
    DSIconsLineConfig["TopArrowToTop"] = "top-arrow-to-top";
    DSIconsLineConfig["Tornado"] = "tornado";
    DSIconsLineConfig["Trademark"] = "trademark";
    DSIconsLineConfig["TrademarkCircle"] = "trademark-circle";
    DSIconsLineConfig["TrafficBarrier"] = "traffic-barrier";
    DSIconsLineConfig["TrafficLight"] = "traffic-light";
    DSIconsLineConfig["Transaction"] = "transaction";
    DSIconsLineConfig["Trash"] = "trash";
    DSIconsLineConfig["TrashAlt"] = "trash-alt";
    DSIconsLineConfig["Trees"] = "trees";
    DSIconsLineConfig["Triangle"] = "triangle";
    DSIconsLineConfig["Trophy"] = "trophy";
    DSIconsLineConfig["Trowel"] = "trowel";
    DSIconsLineConfig["Truck"] = "truck";
    DSIconsLineConfig["TruckLoading"] = "truck-loading";
    DSIconsLineConfig["Tumblr"] = "tumblr";
    DSIconsLineConfig["TumblrAlt"] = "tumblr-alt";
    DSIconsLineConfig["TumblrSquare"] = "tumblr-square";
    DSIconsLineConfig["TvRetro"] = "tv-retro";
    DSIconsLineConfig["TvRetroSlash"] = "tv-retro-slash";
    DSIconsLineConfig["TwelvePlus"] = "twelve-plus";
    DSIconsLineConfig["TwentyonePlus"] = "twentyone-plus";
    DSIconsLineConfig["Twitter"] = "twitter";
    DSIconsLineConfig["Umbrella"] = "umbrella";
    DSIconsLineConfig["Unamused"] = "unamused";
    DSIconsLineConfig["Underline"] = "underline";
    DSIconsLineConfig["University"] = "university";
    DSIconsLineConfig["Unlock"] = "unlock";
    DSIconsLineConfig["UnlockAlt"] = "unlock-alt";
    DSIconsLineConfig["Upload"] = "upload";
    DSIconsLineConfig["UploadAlt"] = "upload-alt";
    DSIconsLineConfig["UsdCircle"] = "usd-circle";
    DSIconsLineConfig["UsdSquare"] = "usd-square";
    DSIconsLineConfig["User"] = "user";
    DSIconsLineConfig["UserArrows"] = "user-arrows";
    DSIconsLineConfig["UserCheck"] = "user-check";
    DSIconsLineConfig["UserCircle"] = "user-circle";
    DSIconsLineConfig["UserExclamation"] = "user-exclamation";
    DSIconsLineConfig["UserLocation"] = "user-location";
    DSIconsLineConfig["UserMd"] = "user-md";
    DSIconsLineConfig["UserMinus"] = "user-minus";
    DSIconsLineConfig["UserNurse"] = "user-nurse";
    DSIconsLineConfig["UserPlus"] = "user-plus";
    DSIconsLineConfig["UserSquare"] = "user-square";
    DSIconsLineConfig["UserTimes"] = "user-times";
    DSIconsLineConfig["UsersAlt"] = "users-alt";
    DSIconsLineConfig["Utensils"] = "utensils";
    DSIconsLineConfig["UtensilsAlt"] = "utensils-alt";
    DSIconsLineConfig["VectorSquare"] = "vector-square";
    DSIconsLineConfig["VectorSquareAlt"] = "vector-square-alt";
    DSIconsLineConfig["Venus"] = "venus";
    DSIconsLineConfig["VerticalAlignBottom"] = "vertical-align-bottom";
    DSIconsLineConfig["VerticalAlignCenter"] = "vertical-align-center";
    DSIconsLineConfig["VerticalAlignTop"] = "vertical-align-top";
    DSIconsLineConfig["VerticalDistributeBottom"] = "vertical-distribute-bottom";
    DSIconsLineConfig["VerticalDistributionCenter"] = "vertical-distribution-center";
    DSIconsLineConfig["VerticalDistributionTop"] = "vertical-distribution-top";
    DSIconsLineConfig["Video"] = "video";
    DSIconsLineConfig["VideoQuestion"] = "video-question";
    DSIconsLineConfig["VideoSlash"] = "video-slash";
    DSIconsLineConfig["VirusSlash"] = "virus-slash";
    DSIconsLineConfig["Visa"] = "visa";
    DSIconsLineConfig["VisualStudio"] = "visual-studio";
    DSIconsLineConfig["Vk"] = "vk";
    DSIconsLineConfig["VkAlt"] = "vk-alt";
    DSIconsLineConfig["Voicemail"] = "voicemail";
    DSIconsLineConfig["VoicemailRectangle"] = "voicemail-rectangle";
    DSIconsLineConfig["Volume"] = "volume";
    DSIconsLineConfig["VolumeDown"] = "volume-down";
    DSIconsLineConfig["VolumeMute"] = "volume-mute";
    DSIconsLineConfig["VolumeOff"] = "volume-off";
    DSIconsLineConfig["VolumeUp"] = "volume-up";
    DSIconsLineConfig["Vuejs"] = "vuejs";
    DSIconsLineConfig["VuejsAlt"] = "vuejs-alt";
    DSIconsLineConfig["Wall"] = "wall";
    DSIconsLineConfig["Wallet"] = "wallet";
    DSIconsLineConfig["Watch"] = "watch";
    DSIconsLineConfig["WatchAlt"] = "watch-alt";
    DSIconsLineConfig["Water"] = "water";
    DSIconsLineConfig["WaterDropSlash"] = "water-drop-slash";
    DSIconsLineConfig["WaterGlass"] = "water-glass";
    DSIconsLineConfig["WebGrid"] = "web-grid";
    DSIconsLineConfig["WebGridAlt"] = "web-grid-alt";
    DSIconsLineConfig["WebSection"] = "web-section";
    DSIconsLineConfig["WebSectionAlt"] = "web-section-alt";
    DSIconsLineConfig["Webcam"] = "webcam";
    DSIconsLineConfig["Weight"] = "weight";
    DSIconsLineConfig["Whatsapp"] = "whatsapp";
    DSIconsLineConfig["WhatsappAlt"] = "whatsapp-alt";
    DSIconsLineConfig["WheelBarrow"] = "wheel-barrow";
    DSIconsLineConfig["Wheelchair"] = "wheelchair";
    DSIconsLineConfig["WheelchairAlt"] = "wheelchair-alt";
    DSIconsLineConfig["Wifi"] = "wifi";
    DSIconsLineConfig["WifiRouter"] = "wifi-router";
    DSIconsLineConfig["WifiSlash"] = "wifi-slash";
    DSIconsLineConfig["Wind"] = "wind";
    DSIconsLineConfig["WindMoon"] = "wind-moon";
    DSIconsLineConfig["WindSun"] = "wind-sun";
    DSIconsLineConfig["Window"] = "window";
    DSIconsLineConfig["WindowGrid"] = "window-grid";
    DSIconsLineConfig["WindowMaximize"] = "window-maximize";
    DSIconsLineConfig["WindowSection"] = "window-section";
    DSIconsLineConfig["Windows"] = "windows";
    DSIconsLineConfig["Windsock"] = "windsock";
    DSIconsLineConfig["Windy"] = "windy";
    DSIconsLineConfig["Wordpress"] = "wordpress";
    DSIconsLineConfig["WordpressSimple"] = "wordpress-simple";
    DSIconsLineConfig["WrapText"] = "wrap-text";
    DSIconsLineConfig["Wrench"] = "wrench";
    DSIconsLineConfig["X"] = "x";
    DSIconsLineConfig["XAdd"] = "x-add";
    DSIconsLineConfig["Yen"] = "yen";
    DSIconsLineConfig["YenCircle"] = "yen-circle";
    DSIconsLineConfig["YinYang"] = "yin-yang";
    DSIconsLineConfig["Youtube"] = "youtube";
    DSIconsLineConfig["ZeroPlus"] = "zero-plus";
})(DSIconsLineConfig = exports.DSIconsLineConfig || (exports.DSIconsLineConfig = {}));
;
var Name = [
    'abacus',
    'accessible-icon-alt',
    'adjust',
    'adjust-alt',
    'adjust-circle',
    'adjust-half',
    'adobe',
    'adobe-alt',
    'airplay',
    'align',
    'align-alt',
    'align-center',
    'align-center-alt',
    'align-center-h',
    'align-center-justify',
    'align-center-v',
    'align-justify',
    'align-left',
    'align-left-justify',
    'align-letter-right',
    'align-right',
    'align-right-justify',
    'amazon',
    'ambulance',
    'american-express',
    'analysis',
    'analytics',
    'anchor',
    'android',
    'android-alt',
    'android-phone-slash',
    'angle-double-down',
    'angle-double-left',
    'angle-double-right',
    'angle-double-up',
    'angle-down',
    'angle-left',
    'angle-left-b',
    'angle-right',
    'angle-right-b',
    'angle-up',
    'angry',
    'ankh',
    'annoyed',
    'annoyed-alt',
    'apple',
    'apple-alt',
    'apps',
    'archive',
    'archive-alt',
    'archway',
    'arrow',
    'arrow-break',
    'arrow-circle-down',
    'arrow-circle-left',
    'arrow-circle-right',
    'arrow-circle-up',
    'arrow-compress-h',
    'arrow-down',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-from-right',
    'arrow-from-top',
    'arrow-growth',
    'arrow-left',
    'arrow-random',
    'arrow-resize-diagonal',
    'arrow-right',
    'arrow-to-bottom',
    'arrow-to-right',
    'arrow-up',
    'arrow-up-left',
    'arrow-up-right',
    'arrows-h',
    'arrows-h-alt',
    'arrows-left-down',
    'arrows-maximize',
    'arrows-merge',
    'arrows-resize',
    'arrows-resize-h',
    'arrows-resize-v',
    'arrows-right-down',
    'arrows-shrink-h',
    'arrows-shrink-v',
    'arrows-up-right',
    'arrows-v',
    'arrows-v-alt',
    'assistive-listening-systems',
    'asterisk',
    'at',
    'atom',
    'auto-flash',
    'award',
    'award-alt',
    'baby-carriage',
    'backpack',
    'backspace',
    'backward',
    'bag',
    'bag-alt',
    'bag-slash',
    'balance-scale',
    'ban',
    'band-aid',
    'bars',
    'baseball-ball',
    'basketball',
    'basketball-hoop',
    'bath',
    'battery-bolt',
    'battery-empty',
    'bed',
    'bed-double',
    'behance',
    'behance-alt',
    'bell',
    'bell-school',
    'bell-slash',
    'bill',
    'bing',
    'bitcoin',
    'bitcoin-alt',
    'bitcoin-circle',
    'bitcoin-sign',
    'black-berry',
    'blogger',
    'blogger-alt',
    'bluetooth-b',
    'bold',
    'boleto',
    'bolt',
    'bolt-alt',
    'bolt-slash',
    'book',
    'book-alt',
    'book-medical',
    'book-open',
    'book-reader',
    'bookmark',
    'bookmark-full',
    'books',
    'boombox',
    'border-alt',
    'border-bottom',
    'border-clear',
    'border-horizontal',
    'border-inner',
    'border-left',
    'border-out',
    'border-right',
    'border-top',
    'border-vertical',
    'bowling-ball',
    'box',
    'brackets-curly',
    'brain',
    'briefcase',
    'briefcase-alt',
    'bright',
    'brightness',
    'brightness-empty',
    'brightness-half',
    'brightness-low',
    'brightness-minus',
    'brightness-plus',
    'bring-bottom',
    'bring-front',
    'browser',
    'brush-alt',
    'bug',
    'building',
    'bullseye',
    'bus',
    'bus-alt',
    'bus-school',
    'calculator',
    'calculator-alt',
    'calendar-alt',
    'calendar-slash',
    'calender',
    'calling',
    'camera',
    'camera-change',
    'camera-plus',
    'camera-slash',
    'cancel',
    'capsule',
    'capture',
    'car',
    'car-sideview',
    'car-slash',
    'car-wash',
    'card-atm',
    'caret-right',
    'cell',
    'celsius',
    'channel',
    'channel-add',
    'chart',
    'chart-bar',
    'chart-bar-alt',
    'chart-down',
    'chart-growth',
    'chart-growth-alt',
    'chart-line',
    'chart-pie',
    'chart-pie-alt',
    'chat',
    'chat-bubble-user',
    'chat-info',
    'check',
    'check-circle',
    'check-square',
    'circle',
    'circle-layer',
    'circuit',
    'clapper-board',
    'clinic-medical',
    'clipboard',
    'clipboard-alt',
    'clipboard-blank',
    'clipboard-notes',
    'clock',
    'clock-eight',
    'clock-five',
    'clock-nine',
    'clock-seven',
    'clock-ten',
    'clock-three',
    'clock-two',
    'closed-captioning',
    'closed-captioning-slash',
    'cloud',
    'cloud-block',
    'cloud-bookmark',
    'cloud-check',
    'cloud-computing',
    'cloud-data-connection',
    'cloud-database-tree',
    'cloud-download',
    'cloud-drizzle',
    'cloud-exclamation',
    'cloud-hail',
    'cloud-heart',
    'cloud-info',
    'cloud-lock',
    'cloud-meatball',
    'cloud-moon',
    'cloud-moon-hail',
    'cloud-moon-meatball',
    'cloud-moon-rain',
    'cloud-moon-showers',
    'cloud-question',
    'cloud-rain',
    'cloud-rain-sun',
    'cloud-redo',
    'cloud-share',
    'cloud-shield',
    'cloud-showers',
    'cloud-showers-alt',
    'cloud-showers-heavy',
    'cloud-slash',
    'cloud-sun',
    'cloud-sun-hail',
    'cloud-sun-meatball',
    'cloud-sun-rain',
    'cloud-sun-rain-alt',
    'cloud-sun-tear',
    'cloud-times',
    'cloud-unlock',
    'cloud-upload',
    'cloud-wifi',
    'cloud-wind',
    'clouds',
    'club',
    'clubz',
    'code-branch',
    'coffee',
    'cog',
    'coins',
    'columns',
    'comment',
    'comment-add',
    'comment-alt',
    'comment-alt-block',
    'comment-alt-chart-lines',
    'comment-alt-check',
    'comment-alt-dots',
    'comment-alt-download',
    'comment-alt-edit',
    'comment-alt-exclamation',
    'comment-alt-heart',
    'comment-alt-image',
    'comment-alt-info',
    'comment-alt-lines',
    'comment-alt-lock',
    'comment-alt-medical',
    'comment-alt-message',
    'comment-alt-notes',
    'comment-alt-plus',
    'comment-alt-question',
    'comment-alt-redo',
    'comment-alt-search',
    'comment-alt-share',
    'comment-alt-shield',
    'comment-alt-slash',
    'comment-alt-upload',
    'comment-alt-verify',
    'comment-block',
    'comment-chart-line',
    'comment-check',
    'comment-dots',
    'comment-download',
    'comment-edit',
    'comment-exclamation',
    'comment-heart',
    'comment-image',
    'comment-info',
    'comment-info-alt',
    'comment-lines',
    'comment-lock',
    'comment-medical',
    'comment-message',
    'comment-notes',
    'comment-plus',
    'comment-question',
    'comment-redo',
    'comment-search',
    'comment-share',
    'comment-shield',
    'comment-slash',
    'comment-upload',
    'comment-verify',
    'comments',
    'comments-alt',
    'compact-disc',
    'comparison',
    'compass',
    'compress',
    'compress-alt',
    'compress-alt-left',
    'compress-arrows',
    'compress-lines',
    'compress-point',
    'compress-v',
    'confused',
    'constructor',
    'copy',
    'copy-alt',
    'copy-landscape',
    'copyright',
    'corner-down-left',
    'corner-down-right',
    'corner-down-right-alt',
    'corner-left-down',
    'corner-right-down',
    'corner-up-left',
    'corner-up-left-alt',
    'corner-up-right',
    'corner-up-right-alt',
    'coronavirus',
    'create-dashboard',
    'creative-commons-pd',
    'credit-card',
    'credit-card-search',
    'crockery',
    'crop-alt',
    'crop-alt-rotate-left',
    'crop-alt-rotate-right',
    'crosshair',
    'crosshair-alt',
    'crosshairs',
    'css3-simple',
    'cube',
    'dashboard',
    'data-sharing',
    'database',
    'database-alt',
    'desert',
    'desktop',
    'desktop-alt',
    'desktop-alt-slash',
    'desktop-cloud-alt',
    'desktop-slash',
    'dialpad',
    'dialpad-alt',
    'diamond',
    'diary',
    'diary-alt',
    'dice-five',
    'dice-four',
    'dice-one',
    'dice-six',
    'dice-three',
    'dice-two',
    'diners-club',
    'direction',
    'directions',
    'discord',
    'dislike',
    'dizzy-meh',
    'dna',
    'docker',
    'document-info',
    'document-layout-center',
    'document-layout-left',
    'document-layout-right',
    'dollar-alt',
    'dollar-sign',
    'dollar-sign-alt',
    'download-alt',
    'draggabledots',
    'dribbble',
    'drill',
    'dropbox',
    'dumbbell',
    'ear',
    'edit',
    'edit-alt',
    'eighteen-plus',
    'elipsis-double-v-alt',
    'ellipsis-h',
    'ellipsis-v',
    'elo',
    'emoji',
    'english-to-chinese',
    'enter',
    'envelope',
    'envelope-add',
    'envelope-alt',
    'envelope-block',
    'envelope-bookmark',
    'envelope-check',
    'envelope-download',
    'envelope-download-alt',
    'envelope-edit',
    'envelope-exclamation',
    'envelope-heart',
    'envelope-info',
    'envelope-lock',
    'envelope-minus',
    'envelope-open',
    'envelope-question',
    'envelope-receive',
    'envelope-redo',
    'envelope-search',
    'envelope-send',
    'envelope-share',
    'envelope-shield',
    'envelope-star',
    'envelope-times',
    'envelope-upload',
    'envelope-upload-alt',
    'envelopes',
    'equal-circle',
    'estate',
    'euro',
    'euro-circle',
    'exchange',
    'exchange-alt',
    'exclamation',
    'exclamation-circle',
    'exclamation-octagon',
    'exclamation-triangle',
    'exclude',
    'exit',
    'expand-alt',
    'expand-arrows',
    'expand-arrows-alt',
    'expand-from-corner',
    'expand-left',
    'expand-right',
    'export',
    'exposure-alt',
    'exposure-increase',
    'external-link-alt',
    'eye',
    'eye-slash',
    'facebook',
    'facebook-f',
    'facebook-messenger',
    'facebook-messenger-alt',
    'fahrenheit',
    'fast-mail',
    'fast-mail-alt',
    'favorite',
    'feedback',
    'fidget-spinner',
    'file',
    'file-alt',
    'file-blank',
    'file-block-alt',
    'file-bookmark-alt',
    'file-check',
    'file-check-alt',
    'file-contract',
    'file-contract-dollar',
    'file-copy-alt',
    'file-download',
    'file-download-alt',
    'file-edit-alt',
    'file-exclamation',
    'file-exclamation-alt',
    'file-export',
    'file-graph',
    'file-heart',
    'file-import',
    'file-info-alt',
    'file-landscape',
    'file-landscape-alt',
    'file-lanscape-slash',
    'file-lock-alt',
    'file-medical',
    'file-medical-alt',
    'file-minus',
    'file-minus-alt',
    'file-network',
    'file-plus',
    'file-plus-alt',
    'file-question',
    'file-question-alt',
    'file-redo-alt',
    'file-search',
    'file-search-alt',
    'file-share-alt',
    'file-shield-alt',
    'file-slash',
    'file-times',
    'file-times-alt',
    'file-upload',
    'file-upload-alt',
    'files-landscapes',
    'files-landscapes-alt',
    'film',
    'filter',
    'filter-slash',
    'fire',
    'fivehundred-px',
    'flask',
    'flask-potion',
    'flip-h',
    'flip-h-alt',
    'flip-v',
    'flip-v-alt',
    'flower',
    'focus',
    'focus-add',
    'focus-target',
    'folder',
    'folder-check',
    'folder-download',
    'folder-exclamation',
    'folder-heart',
    'folder-info',
    'folder-lock',
    'folder-medical',
    'folder-minus',
    'folder-network',
    'folder-open',
    'folder-plus',
    'folder-question',
    'folder-slash',
    'folder-times',
    'folder-upload',
    'font',
    'football',
    'football-american',
    'football-ball',
    'forecastcloud-moon-tear',
    'forwaded-call',
    'forward',
    'frown',
    'game-structure',
    'gift',
    'github',
    'github-alt',
    'gitlab',
    'glass',
    'glass-martini',
    'glass-martini-alt',
    'glass-martini-alt-slash',
    'glass-tea',
    'globe',
    'gold',
    'golf-ball',
    'google',
    'google-drive',
    'google-drive-alt',
    'google-hangouts',
    'google-hangouts-alt',
    'google-play',
    'graduation-cap',
    'graph-bar',
    'grid',
    'grids',
    'grin',
    'grin-tongue-wink',
    'grin-tongue-wink-alt',
    'grip-horizontal-line',
    'hard-hat',
    'hdd',
    'head-side',
    'head-side-cough',
    'head-side-mask',
    'headphone-slash',
    'headphones',
    'headphones-alt',
    'heart',
    'heart-alt',
    'heart-break',
    'heart-medical',
    'heart-rate',
    'heart-sign',
    'heartbeat',
    'hindi-to-chinese',
    'hipchat',
    'hipercard',
    'history',
    'history-alt',
    'home',
    'home-alt',
    'horizontal-align-center',
    'horizontal-align-left',
    'horizontal-align-right',
    'horizontal-distribution-center',
    'horizontal-distribution-left',
    'horizontal-distribution-right',
    'hospital',
    'hospital-square-sign',
    'hospital-symbol',
    'hourglass',
    'house-user',
    'html3',
    'html3-alt',
    'html5',
    'html5-alt',
    'hunting',
    'icons',
    'illustration',
    'image',
    'image-alt-slash',
    'image-block',
    'image-broken',
    'image-check',
    'image-download',
    'image-edit',
    'image-lock',
    'image-minus',
    'image-plus',
    'image-question',
    'image-redo',
    'image-resize-landscape',
    'image-resize-square',
    'image-search',
    'image-share',
    'image-shield',
    'image-slash',
    'image-times',
    'image-upload',
    'image-v',
    'images',
    'import-action',
    'inbox',
    'incoming-call',
    'info',
    'info-circle',
    'instagram',
    'instagram-alt',
    'intercom',
    'intercom-alt',
    'invoice',
    'italic',
    'jackhammer',
    'java-script',
    'kayak',
    'key-skeleton',
    'key-skeleton-alt',
    'keyboard',
    'keyboard-alt',
    'keyboard-hide',
    'keyboard-show',
    'keyhole-circle',
    'keyhole-square',
    'keyhole-square-full',
    'keylines',
    'kid',
    'label',
    'label-alt',
    'lamp',
    'language',
    'laptop',
    'laptop-cloud',
    'laptop-connection',
    'laughing',
    'layer-group',
    'layer-group-slash',
    'layers',
    'layers-alt',
    'layers-slash',
    'left-arrow-from-left',
    'left-arrow-to-left',
    'left-indent',
    'left-indent-alt',
    'left-to-right-text-direction',
    'letter-chinese-a',
    'letter-english-a',
    'letter-hindi-a',
    'letter-japanese-a',
    'life-ring',
    'lightbulb',
    'lightbulb-alt',
    'like',
    'line',
    'line-alt',
    'line-spacing',
    'link',
    'link-add',
    'link-alt',
    'link-broken',
    'link-h',
    'linkedin',
    'linkedin-alt',
    'linux',
    'lira-sign',
    'list-ol',
    'list-ui-alt',
    'list-ul',
    'load',
    'location-arrow',
    'location-arrow-alt',
    'location-pin-alt',
    'location-point',
    'lock',
    'lock-access',
    'lock-alt',
    'lock-open-alt',
    'lock-slash',
    'lottiefiles',
    'lottiefiles-alt',
    'luggage-cart',
    'mailbox',
    'mailbox-alt',
    'map',
    'map-marker',
    'map-marker-alt',
    'map-marker-edit',
    'map-marker-info',
    'map-marker-minus',
    'map-marker-plus',
    'map-marker-question',
    'map-marker-shield',
    'map-marker-slash',
    'map-pin',
    'map-pin-alt',
    'mars',
    'master-card',
    'mastercard',
    'maximize-left',
    'medal',
    'medical-drip',
    'medical-square',
    'medical-square-full',
    'medium-m',
    'medkit',
    'meeting-board',
    'megaphone',
    'meh',
    'meh-alt',
    'meh-closed-eye',
    'message',
    'metro',
    'microphone',
    'microphone-slash',
    'microscope',
    'microsoft',
    'minus',
    'minus-circle',
    'minus-path',
    'minus-square',
    'minus-square-full',
    'missed-call',
    'mobile-android',
    'mobile-android-alt',
    'mobile-vibrate',
    'modem',
    'money-bill',
    'money-bill-slash',
    'money-bill-stack',
    'money-insert',
    'money-stack',
    'money-withdraw',
    'money-withdrawal',
    'moneybag',
    'moneybag-alt',
    'monitor',
    'monitor-heart-rate',
    'moon',
    'moon-eclipse',
    'moonset',
    'mountains',
    'mountains-sun',
    'mouse',
    'mouse-alt',
    'mouse-alt-2',
    'multiply',
    'music',
    'music-note',
    'music-tune-slash',
    'n-a',
    'navigator',
    'nerd',
    'newspaper',
    'ninja',
    'no-entry',
    'notebooks',
    'notes',
    'object-group',
    'object-ungroup',
    'octagon',
    'okta',
    'opera',
    'opera-alt',
    'outgoing-call',
    'packet',
    'padlock',
    'pagelines',
    'pagerduty',
    'paint-tool',
    'palette',
    'panel-add',
    'panorama-h',
    'panorama-h-alt',
    'panorama-v',
    'paperclip',
    'paragraph',
    'parcel',
    'parking-circle',
    'parking-square',
    'pathfinder',
    'pathfinder-unite',
    'pause',
    'pause-circle',
    'paypal',
    'pen',
    'pentagon',
    'percent',
    'percentage',
    'petz-argan',
    'petz-bag',
    'petz-banho-e-tosa',
    'petz-barcode',
    'petz-bath',
    'petz-calendar',
    'petz-cancel',
    'petz-card',
    'petz-chevron-down',
    'petz-chevron-left',
    'petz-chevron-right',
    'petz-chevron-up',
    'petz-clubz',
    'petz-coupon',
    'petz-delivery',
    'petz-discount',
    'petz-escape',
    'petz-faq',
    'petz-favorite',
    'petz-filter',
    'petz-funnel',
    'petz-furminator',
    'petz-grooming',
    'petz-hydra',
    'petz-location',
    'petz-map-marker',
    'petz-menu',
    'petz-minus',
    'petz-my-pet',
    'petz-notification',
    'petz-order',
    'petz-package',
    'petz-pix',
    'petz-profile',
    'petz-rebuy',
    'petz-recompra',
    'petz-refresh',
    'petz-scissors',
    'petz-search',
    'petz-shaver',
    'petz-shop',
    'petz-stripping',
    'petz-subscription',
    'petz-toning',
    'petz-trash',
    'petz-tune',
    'petz-vet-cardio',
    'petz-vet-kit',
    'petz-vet-phone',
    'phone',
    'phone-alt',
    'phone-pause',
    'phone-slash',
    'phone-times',
    'phone-volume',
    'picture',
    'pinterest',
    'pizza-slice',
    'plane',
    'plane-arrival',
    'plane-departure',
    'plane-fly',
    'play',
    'play-circle',
    'plug',
    'plus',
    'plus-circle',
    'plus-square',
    'podium',
    'polygon',
    'post-stamp',
    'postcard',
    'pound',
    'pound-circle',
    'power',
    'prescription-bottle',
    'presentation',
    'presentation-check',
    'presentation-edit',
    'presentation-line',
    'presentation-lines-alt',
    'presentation-minus',
    'presentation-play',
    'presentation-plus',
    'presentation-times',
    'previous',
    'pricetag-alt',
    'print',
    'print-slash',
    'process',
    'processor',
    'programming-language',
    'pump',
    'puzzle-piece',
    'qrcode-scan',
    'question',
    'question-circle',
    'rainbow',
    'raindrops',
    'raindrops-alt',
    'react',
    'receipt',
    'receipt-alt',
    'record-audio',
    'reddit-alien-alt',
    'redo',
    'refresh',
    'registered',
    'repeat',
    'restaurant',
    'right-indent-alt',
    'right-to-left-text-direction',
    'robot',
    'rocket',
    'rope-way',
    'rotate-360',
    'rotate-screen',
    'rss',
    'rss-alt',
    'rss-interface',
    'ruler',
    'ruler-combined',
    'rupee-sign',
    'sad',
    'sad-cry',
    'sad-crying',
    'sad-dizzy',
    'sad-squint',
    'sanitizer',
    'sanitizer-alt',
    'save',
    'scaling-left',
    'scaling-right',
    'scenery',
    'schedule',
    'screw',
    'scroll',
    'scroll-h',
    'search',
    'search-alt',
    'search-minus',
    'search-plus',
    'selfie',
    'server',
    'server-alt',
    'server-connection',
    'server-network',
    'server-network-alt',
    'servers',
    'servicemark',
    'setting',
    'seventeen-plus',
    'share',
    'share-alt',
    'shelf',
    'shield',
    'shield-check',
    'shield-exclamation',
    'shield-plus',
    'shield-question',
    'shield-slash',
    'ship',
    'shop',
    'shopping-bag',
    'shopping-basket',
    'shopping-cart',
    'shopping-cart-alt',
    'shovel',
    'shrink',
    'shuffle',
    'shutter',
    'shutter-alt',
    'sick',
    'side-menu',
    'sigma',
    'sign-alt',
    'sign-in-alt',
    'sign-left',
    'sign-out-alt',
    'sign-right',
    'signal',
    'signal-alt',
    'signal-alt-3',
    'signin',
    'signout',
    'silence',
    'silent-squint',
    'sim-card',
    'sitemap',
    'six-plus',
    'sixteen-plus',
    'skip-forward',
    'skip-forward-alt',
    'skip-forward-circle',
    'skype',
    'skype-alt',
    'slack',
    'slack-alt',
    'slider-h',
    'slider-h-range',
    'sliders-v',
    'sliders-v-alt',
    'smile',
    'smile-beam',
    'smile-dizzy',
    'smile-squint-wink',
    'smile-squint-wink-alt',
    'smile-wink',
    'smile-wink-alt',
    'snapchat-alt',
    'snapchat-ghost',
    'snapchat-square',
    'snow-flake',
    'snowflake',
    'snowflake-alt',
    'social-distancing',
    'sort',
    'sort-amount-down',
    'sort-amount-up',
    'sorting',
    'space-key',
    'spade',
    'sperms',
    'spin',
    'spinner',
    'spinner-alt',
    'square',
    'square-full',
    'square-shape',
    'squint',
    'star',
    'star-half-alt',
    'step-backward',
    'step-backward-alt',
    'step-backward-circle',
    'step-forward',
    'stethoscope',
    'stethoscope-alt',
    'stop-circle',
    'stopwatch',
    'stopwatch-slash',
    'store',
    'store-alt',
    'store-slash',
    'streering',
    'stretcher',
    'subject',
    'subway',
    'subway-alt',
    'suitcase',
    'suitcase-alt',
    'sun',
    'sunset',
    'surprise',
    'swatchbook',
    'swiggy',
    'swimmer',
    'sync',
    'sync-exclamation',
    'sync-slash',
    'syringe',
    'table',
    'table-tennis',
    'tablet',
    'tablets',
    'tachometer-fast',
    'tachometer-fast-alt',
    'tag',
    'tag-alt',
    'tape',
    'taxi',
    'tear',
    'telegram',
    'telegram-alt',
    'telescope',
    'temperature',
    'temperature-empty',
    'temperature-half',
    'temperature-minus',
    'temperature-plus',
    'temperature-quarter',
    'temperature-three-quarter',
    'ten-plus',
    'tennis-ball',
    'text',
    'text-fields',
    'text-size',
    'text-strike-through',
    'th',
    'th-large',
    'th-slash',
    'thermometer',
    'thirteen-plus',
    'three-plus',
    'thumbs-down',
    'thumbs-up',
    'thunderstorm',
    'thunderstorm-moon',
    'thunderstorm-sun',
    'ticket',
    'tiktok',
    'times',
    'times-circle',
    'times-square',
    'toggle-off',
    'toggle-on',
    'toilet-paper',
    'top-arrow-from-top',
    'top-arrow-to-top',
    'tornado',
    'trademark',
    'trademark-circle',
    'traffic-barrier',
    'traffic-light',
    'transaction',
    'trash',
    'trash-alt',
    'trees',
    'triangle',
    'trophy',
    'trowel',
    'truck',
    'truck-loading',
    'tumblr',
    'tumblr-alt',
    'tumblr-square',
    'tv-retro',
    'tv-retro-slash',
    'twelve-plus',
    'twentyone-plus',
    'twitter',
    'umbrella',
    'unamused',
    'underline',
    'university',
    'unlock',
    'unlock-alt',
    'upload',
    'upload-alt',
    'usd-circle',
    'usd-square',
    'user',
    'user-arrows',
    'user-check',
    'user-circle',
    'user-exclamation',
    'user-location',
    'user-md',
    'user-minus',
    'user-nurse',
    'user-plus',
    'user-square',
    'user-times',
    'users-alt',
    'utensils',
    'utensils-alt',
    'vector-square',
    'vector-square-alt',
    'venus',
    'vertical-align-bottom',
    'vertical-align-center',
    'vertical-align-top',
    'vertical-distribute-bottom',
    'vertical-distribution-center',
    'vertical-distribution-top',
    'video',
    'video-question',
    'video-slash',
    'virus-slash',
    'visa',
    'visual-studio',
    'vk',
    'vk-alt',
    'voicemail',
    'voicemail-rectangle',
    'volume',
    'volume-down',
    'volume-mute',
    'volume-off',
    'volume-up',
    'vuejs',
    'vuejs-alt',
    'wall',
    'wallet',
    'watch',
    'watch-alt',
    'water',
    'water-drop-slash',
    'water-glass',
    'web-grid',
    'web-grid-alt',
    'web-section',
    'web-section-alt',
    'webcam',
    'weight',
    'whatsapp',
    'whatsapp-alt',
    'wheel-barrow',
    'wheelchair',
    'wheelchair-alt',
    'wifi',
    'wifi-router',
    'wifi-slash',
    'wind',
    'wind-moon',
    'wind-sun',
    'window',
    'window-grid',
    'window-maximize',
    'window-section',
    'windows',
    'windsock',
    'windy',
    'wordpress',
    'wordpress-simple',
    'wrap-text',
    'wrench',
    'x',
    'x-add',
    'yen',
    'yen-circle',
    'yin-yang',
    'youtube',
    'zero-plus',
];
var DSIconsLine = { Unicode: Unicode, Name: Name };
exports["default"] = DSIconsLine;
