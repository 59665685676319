import DSIconsLine, { DSIconsLineConfig } from '@petz/ds-icons/line';
import DSIconsSolid, { DSIconsSolidConfig } from '@petz/ds-icons/solid';
import { PTZTokensColor, PTZTokensColorConfig } from '@petz/ds-tokens/petz';
import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Align {
  Left = 'left',
  Right = 'right',
}

enum Size {
  Size3xs = '3xs',
  Size2xs = '2xs',
  SizeXs = 'xs',
  SizeSm = 'sm',
  SizeMd = 'md',
  SizeLg = 'lg',
  SizeXl = 'xl',
  Size2xl = '2xl',
  Size3xl = '3xl',
  SizeMax = 'max',
}

enum Variant {
  Solid = 'solid',
  Line = 'line',
}

export const PTZIconConfigList = {
  Align: EnumToArray([Align]),
  Brand: PTZBrandConfigList.Name,
  Color: PTZTokensColor,
  Name: [...DSIconsLine.Name, ...DSIconsSolid.Name],
  Size: EnumToArray([Size]),
  Variant: EnumToArray([Variant]),
};

export const PTZIconConfig = {
  Align,
  Brand: PTZBrandConfig.Name,
  Color: PTZTokensColorConfig,
  Name: { ...DSIconsSolidConfig, ...DSIconsLineConfig },
  Size,
  Variant,
};
