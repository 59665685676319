"use strict";
exports.__esModule = true;
exports.DSIconsSolidConfig = void 0;
var Unicode = {
    AirplaySolid: '\f101',
    AlignAltSolid: '\f102',
    AlignCenterJustifySolid: '\f103',
    AlignCenterSolid: '\f104',
    AlignJustifySolid: '\f105',
    AlignLeftJustifySolid: '\f106',
    AlignLeftSolid: '\f107',
    AlignLetterRightSolid: '\f108',
    AlignRightJustifySolid: '\f109',
    AlignRightSolid: '\f10a',
    AnalysisSolid: '\f10b',
    AnalyticsSolid: '\f10c',
    AnchorSolid: '\f10d',
    AngleDoubleDownSolid: '\f10e',
    AngleDoubleLeftSolid: '\f10f',
    AngleDoubleRightSolid: '\f110',
    AngleDoubleUpSolid: '\f111',
    AngleDownSolid: '\f112',
    AngleLeftSolid: '\f113',
    AngleRightBSolid: '\f114',
    AngleRightSolid: '\f115',
    AngleUpSolid: '\f116',
    AppsSolid: '\f117',
    ArrowCircleDownSolid: '\f118',
    ArrowCircleLeftSolid: '\f119',
    ArrowCircleRightSolid: '\f11a',
    ArrowCircleUpSolid: '\f11b',
    ArrowDownLeftSolid: '\f11c',
    ArrowDownRightSolid: '\f11d',
    ArrowUpLeftSolid: '\f11e',
    ArrowUpRightSolid: '\f11f',
    AtSolid: '\f120',
    BagSolid: '\f121',
    BarsSolid: '\f122',
    BatteryBoltSolid: '\f123',
    BatteryEmptySolid: '\f124',
    BoltSlashSolid: '\f125',
    BoltSolid: '\f126',
    BookmarkSolid: '\f127',
    BorderAltSolid: '\f128',
    BorderBottomSolid: '\f129',
    BorderClearSolid: '\f12a',
    BorderHorizontalSolid: '\f12b',
    BorderInnerSolid: '\f12c',
    BorderLeftSolid: '\f12d',
    BorderOutSolid: '\f12e',
    BorderRightSolid: '\f12f',
    BorderTopSolid: '\f130',
    BorderVerticalSolid: '\f131',
    BriefcaseSolid: '\f132',
    CalendarSolid: '\f133',
    ChartPieSolid: '\f134',
    ChartSolid: '\f135',
    CheckCircleSolid: '\f136',
    CheckSolid: '\f137',
    CheckSquareSolid: '\f138',
    CircleLayerSolid: '\f139',
    ClinicMedicalSolid: '\f13a',
    ClockEightSolid: '\f13b',
    ClockFiveSolid: '\f13c',
    ClockNineSolid: '\f13d',
    ClockSevenSolid: '\f13e',
    ClockSolid: '\f13f',
    ClockTenSolid: '\f140',
    ClockThreeSolid: '\f141',
    ClockTwoSolid: '\f142',
    ColumnsSolid: '\f143',
    CommentDotsSolid: '\f144',
    CompressSolid: '\f145',
    CornerDownLeftSolid: '\f146',
    CornerDownRightSolid: '\f147',
    CornerLeftDownSolid: '\f148',
    CornerRightDownSolid: '\f149',
    CornerUpLeftSolid: '\f14a',
    CornerUpRightSolid: '\f14b',
    CoronavirusSolid: '\f14c',
    DialpadSolid: '\f14d',
    DirectionSolid: '\f14e',
    DislikeSolid: '\f14f',
    DocumentLayoutCenterSolid: '\f150',
    DocumentLayoutLeftSolid: '\f151',
    DocumentLayoutRightSolid: '\f152',
    DownloadAltSolid: '\f153',
    EllipsisHSolid: '\f154',
    EllipsisVSolid: '\f155',
    ExclamationCircleSolid: '\f156',
    ExclamationOctagonSolid: '\f157',
    ExclamationTriangleSolid: '\f158',
    FavoriteSolid: '\f159',
    FlipHAltSolid: '\f15a',
    FlipHSolid: '\f15b',
    FlipVAltSolid: '\f15c',
    FlipVSolid: '\f15d',
    GraphBarSolid: '\f15e',
    GridSolid: '\f15f',
    GridsSolid: '\f160',
    GripHorizontalLineSolid: '\f161',
    HeadSideCoughSolid: '\f162',
    HeadSideMaskSolid: '\f163',
    HeadSideSolid: '\f164',
    HistoryAltSolid: '\f165',
    HistorySolid: '\f166',
    HorizontalAlignLeftSolid: '\f167',
    HospitalSolid: '\f168',
    HospitalSquareSignSolid: '\f169',
    HospitalSymbolSolid: '\f16a',
    HouseUserSolid: '\f16b',
    ImageVSolid: '\f16c',
    KeySkeletonAltSolid: '\f16d',
    KeySkeletonSolid: '\f16e',
    KeyholeCircleSolid: '\f16f',
    KeyholeSquareFullSolid: '\f170',
    KeyholeSquareSolid: '\f171',
    LayerGroupSolid: '\f172',
    LayersAltSolid: '\f173',
    LeftIndentAltSolid: '\f174',
    LeftIndentSolid: '\f175',
    LikeSolid: '\f176',
    LineSpacingSolid: '\f177',
    LinkHSolid: '\f178',
    ListUiAltSolid: '\f179',
    ListUlSolid: '\f17a',
    LockAccessSolid: '\f17b',
    LockAltSolid: '\f17c',
    LockOpenAltSolid: '\f17d',
    LockSolid: '\f17e',
    MapMarkerSolid: '\f17f',
    MicroscopeSolid: '\f180',
    MinusSquareFullSolid: '\f181',
    MultiplySolid: '\f182',
    ObjectGroupSolid: '\f183',
    ObjectUngroupSolid: '\f184',
    PadlockSolid: '\f185',
    PaperclipSolid: '\f186',
    ParagraphSolid: '\f187',
    PauseSolid: '\f188',
    PentagonSolid: '\f189',
    PetzFavoriteSolid: '\f18a',
    PolygonSolid: '\f18b',
    PreviousSolid: '\f18c',
    ProcessSolid: '\f18d',
    RecordAudioSolid: '\f18e',
    RedoSolid: '\f18f',
    RefreshSolid: '\f190',
    RepeatSolid: '\f191',
    RightIndentAltSolid: '\f192',
    RightIndentSolid: '\f193',
    RocketSolid: '\f194',
    RulerCombinedSolid: '\f195',
    RulerSolid: '\f196',
    SanitizerAltSolid: '\f197',
    SanitizerSolid: '\f198',
    ScenerySolid: '\f199',
    ScheduleSolid: '\f19a',
    ShieldPlusSolid: '\f19b',
    SideMenuColor: '\f19c',
    SignalAlt3Solid: '\f19d',
    SignalAltSolid: '\f19e',
    SignoutSolid: '\f19f',
    SocialDistancingSolid: '\f1a0',
    SortingSolid: '\f1a1',
    SpaceKeySolid: '\f1a2',
    SquareFullSolid: '\f1a3',
    StarHalfAltSolid: '\f1a4',
    StarSolid: '\f1a5',
    StepForwardSolid: '\f1a6',
    StethoscopeAltSolid: '\f1a7',
    StethoscopeSolid: '\f1a8',
    StopwatchSolid: '\f1a9',
    StoreSlashSolid: '\f1aa',
    SubjectSolid: '\f1ab',
    SyncExclamationSolid: '\f1ac',
    SyncSlashSolid: '\f1ad',
    TableSolid: '\f1ae',
    ThLargeSolid: '\f1af',
    TimesCircleSolid: '\f1b0',
    ToggleOffSolid: '\f1b1',
    ToggleOnSolid: '\f1b2',
    ToiletPaperSolid: '\f1b3',
    TriangleSolid: '\f1b4',
    UnlockAltSolid: '\f1b5',
    UnlockSolid: '\f1b6',
    UploadAltSolid: '\f1b7',
    UserArrowsSolid: '\f1b8',
    UserMdSolid: '\f1b9',
    UserNurseSolid: '\f1ba',
    VectorSquareAltSolid: '\f1bb',
    VectorSquareSolid: '\f1bc',
    VirusSlashSolid: '\f1bd',
    WebGridAltSolid: '\f1be',
    WebGridSolid: '\f1bf',
    WebSectionAltSolid: '\f1c0',
    WebSectionSolid: '\f1c1',
    WindowGridSolid: '\f1c2',
    WindowMaximizeSolid: '\f1c3',
    WindowSectionSolid: '\f1c4',
    WrapTextSolid: '\f1c5'
};
var DSIconsSolidConfig;
(function (DSIconsSolidConfig) {
    DSIconsSolidConfig["AirplaySolid"] = "airplay-solid";
    DSIconsSolidConfig["AlignAltSolid"] = "align-alt-solid";
    DSIconsSolidConfig["AlignCenterJustifySolid"] = "align-center-justify-solid";
    DSIconsSolidConfig["AlignCenterSolid"] = "align-center-solid";
    DSIconsSolidConfig["AlignJustifySolid"] = "align-justify-solid";
    DSIconsSolidConfig["AlignLeftJustifySolid"] = "align-left-justify-solid";
    DSIconsSolidConfig["AlignLeftSolid"] = "align-left-solid";
    DSIconsSolidConfig["AlignLetterRightSolid"] = "align-letter-right-solid";
    DSIconsSolidConfig["AlignRightJustifySolid"] = "align-right-justify-solid";
    DSIconsSolidConfig["AlignRightSolid"] = "align-right-solid";
    DSIconsSolidConfig["AnalysisSolid"] = "analysis-solid";
    DSIconsSolidConfig["AnalyticsSolid"] = "analytics-solid";
    DSIconsSolidConfig["AnchorSolid"] = "anchor-solid";
    DSIconsSolidConfig["AngleDoubleDownSolid"] = "angle-double-down-solid";
    DSIconsSolidConfig["AngleDoubleLeftSolid"] = "angle-double-left-solid";
    DSIconsSolidConfig["AngleDoubleRightSolid"] = "angle-double-right-solid";
    DSIconsSolidConfig["AngleDoubleUpSolid"] = "angle-double-up-solid";
    DSIconsSolidConfig["AngleDownSolid"] = "angle-down-solid";
    DSIconsSolidConfig["AngleLeftSolid"] = "angle-left-solid";
    DSIconsSolidConfig["AngleRightBSolid"] = "angle-right-b-solid";
    DSIconsSolidConfig["AngleRightSolid"] = "angle-right-solid";
    DSIconsSolidConfig["AngleUpSolid"] = "angle-up-solid";
    DSIconsSolidConfig["AppsSolid"] = "apps-solid";
    DSIconsSolidConfig["ArrowCircleDownSolid"] = "arrow-circle-down-solid";
    DSIconsSolidConfig["ArrowCircleLeftSolid"] = "arrow-circle-left-solid";
    DSIconsSolidConfig["ArrowCircleRightSolid"] = "arrow-circle-right-solid";
    DSIconsSolidConfig["ArrowCircleUpSolid"] = "arrow-circle-up-solid";
    DSIconsSolidConfig["ArrowDownLeftSolid"] = "arrow-down-left-solid";
    DSIconsSolidConfig["ArrowDownRightSolid"] = "arrow-down-right-solid";
    DSIconsSolidConfig["ArrowUpLeftSolid"] = "arrow-up-left-solid";
    DSIconsSolidConfig["ArrowUpRightSolid"] = "arrow-up-right-solid";
    DSIconsSolidConfig["AtSolid"] = "at-solid";
    DSIconsSolidConfig["BagSolid"] = "bag-solid";
    DSIconsSolidConfig["BarsSolid"] = "bars-solid";
    DSIconsSolidConfig["BatteryBoltSolid"] = "battery-bolt-solid";
    DSIconsSolidConfig["BatteryEmptySolid"] = "battery-empty-solid";
    DSIconsSolidConfig["BoltSlashSolid"] = "bolt-slash-solid";
    DSIconsSolidConfig["BoltSolid"] = "bolt-solid";
    DSIconsSolidConfig["BookmarkSolid"] = "bookmark-solid";
    DSIconsSolidConfig["BorderAltSolid"] = "border-alt-solid";
    DSIconsSolidConfig["BorderBottomSolid"] = "border-bottom-solid";
    DSIconsSolidConfig["BorderClearSolid"] = "border-clear-solid";
    DSIconsSolidConfig["BorderHorizontalSolid"] = "border-horizontal-solid";
    DSIconsSolidConfig["BorderInnerSolid"] = "border-inner-solid";
    DSIconsSolidConfig["BorderLeftSolid"] = "border-left-solid";
    DSIconsSolidConfig["BorderOutSolid"] = "border-out-solid";
    DSIconsSolidConfig["BorderRightSolid"] = "border-right-solid";
    DSIconsSolidConfig["BorderTopSolid"] = "border-top-solid";
    DSIconsSolidConfig["BorderVerticalSolid"] = "border-vertical-solid";
    DSIconsSolidConfig["BriefcaseSolid"] = "briefcase-solid";
    DSIconsSolidConfig["CalendarSolid"] = "calendar-solid";
    DSIconsSolidConfig["ChartPieSolid"] = "chart-pie-solid";
    DSIconsSolidConfig["ChartSolid"] = "chart-solid";
    DSIconsSolidConfig["CheckCircleSolid"] = "check-circle-solid";
    DSIconsSolidConfig["CheckSolid"] = "check-solid";
    DSIconsSolidConfig["CheckSquareSolid"] = "check-square-solid";
    DSIconsSolidConfig["CircleLayerSolid"] = "circle-layer-solid";
    DSIconsSolidConfig["ClinicMedicalSolid"] = "clinic-medical-solid";
    DSIconsSolidConfig["ClockEightSolid"] = "clock-eight-solid";
    DSIconsSolidConfig["ClockFiveSolid"] = "clock-five-solid";
    DSIconsSolidConfig["ClockNineSolid"] = "clock-nine-solid";
    DSIconsSolidConfig["ClockSevenSolid"] = "clock-seven-solid";
    DSIconsSolidConfig["ClockSolid"] = "clock-solid";
    DSIconsSolidConfig["ClockTenSolid"] = "clock-ten-solid";
    DSIconsSolidConfig["ClockThreeSolid"] = "clock-three-solid";
    DSIconsSolidConfig["ClockTwoSolid"] = "clock-two-solid";
    DSIconsSolidConfig["ColumnsSolid"] = "columns-solid";
    DSIconsSolidConfig["CommentDotsSolid"] = "comment-dots-solid";
    DSIconsSolidConfig["CompressSolid"] = "compress-solid";
    DSIconsSolidConfig["CornerDownLeftSolid"] = "corner-down-left-solid";
    DSIconsSolidConfig["CornerDownRightSolid"] = "corner-down-right-solid";
    DSIconsSolidConfig["CornerLeftDownSolid"] = "corner-left-down-solid";
    DSIconsSolidConfig["CornerRightDownSolid"] = "corner-right-down-solid";
    DSIconsSolidConfig["CornerUpLeftSolid"] = "corner-up-left-solid";
    DSIconsSolidConfig["CornerUpRightSolid"] = "corner-up-right-solid";
    DSIconsSolidConfig["CoronavirusSolid"] = "coronavirus-solid";
    DSIconsSolidConfig["DialpadSolid"] = "dialpad-solid";
    DSIconsSolidConfig["DirectionSolid"] = "direction-solid";
    DSIconsSolidConfig["DislikeSolid"] = "dislike-solid";
    DSIconsSolidConfig["DocumentLayoutCenterSolid"] = "document-layout-center-solid";
    DSIconsSolidConfig["DocumentLayoutLeftSolid"] = "document-layout-left-solid";
    DSIconsSolidConfig["DocumentLayoutRightSolid"] = "document-layout-right-solid";
    DSIconsSolidConfig["DownloadAltSolid"] = "download-alt-solid";
    DSIconsSolidConfig["EllipsisHSolid"] = "ellipsis-h-solid";
    DSIconsSolidConfig["EllipsisVSolid"] = "ellipsis-v-solid";
    DSIconsSolidConfig["ExclamationCircleSolid"] = "exclamation-circle-solid";
    DSIconsSolidConfig["ExclamationOctagonSolid"] = "exclamation-octagon-solid";
    DSIconsSolidConfig["ExclamationTriangleSolid"] = "exclamation-triangle-solid";
    DSIconsSolidConfig["FavoriteSolid"] = "favorite-solid";
    DSIconsSolidConfig["FlipHAltSolid"] = "flip-h-alt-solid";
    DSIconsSolidConfig["FlipHSolid"] = "flip-h-solid";
    DSIconsSolidConfig["FlipVAltSolid"] = "flip-v-alt-solid";
    DSIconsSolidConfig["FlipVSolid"] = "flip-v-solid";
    DSIconsSolidConfig["GraphBarSolid"] = "graph-bar-solid";
    DSIconsSolidConfig["GridSolid"] = "grid-solid";
    DSIconsSolidConfig["GridsSolid"] = "grids-solid";
    DSIconsSolidConfig["GripHorizontalLineSolid"] = "grip-horizontal-line-solid";
    DSIconsSolidConfig["HeadSideCoughSolid"] = "head-side-cough-solid";
    DSIconsSolidConfig["HeadSideMaskSolid"] = "head-side-mask-solid";
    DSIconsSolidConfig["HeadSideSolid"] = "head-side-solid";
    DSIconsSolidConfig["HistoryAltSolid"] = "history-alt-solid";
    DSIconsSolidConfig["HistorySolid"] = "history-solid";
    DSIconsSolidConfig["HorizontalAlignLeftSolid"] = "horizontal-align-left-solid";
    DSIconsSolidConfig["HospitalSolid"] = "hospital-solid";
    DSIconsSolidConfig["HospitalSquareSignSolid"] = "hospital-square-sign-solid";
    DSIconsSolidConfig["HospitalSymbolSolid"] = "hospital-symbol-solid";
    DSIconsSolidConfig["HouseUserSolid"] = "house-user-solid";
    DSIconsSolidConfig["ImageVSolid"] = "image-v-solid";
    DSIconsSolidConfig["KeySkeletonAltSolid"] = "key-skeleton-alt-solid";
    DSIconsSolidConfig["KeySkeletonSolid"] = "key-skeleton-solid";
    DSIconsSolidConfig["KeyholeCircleSolid"] = "keyhole-circle-solid";
    DSIconsSolidConfig["KeyholeSquareFullSolid"] = "keyhole-square-full-solid";
    DSIconsSolidConfig["KeyholeSquareSolid"] = "keyhole-square-solid";
    DSIconsSolidConfig["LayerGroupSolid"] = "layer-group-solid";
    DSIconsSolidConfig["LayersAltSolid"] = "layers-alt-solid";
    DSIconsSolidConfig["LeftIndentAltSolid"] = "left-indent-alt-solid";
    DSIconsSolidConfig["LeftIndentSolid"] = "left-indent-solid";
    DSIconsSolidConfig["LikeSolid"] = "like-solid";
    DSIconsSolidConfig["LineSpacingSolid"] = "line-spacing-solid";
    DSIconsSolidConfig["LinkHSolid"] = "link-h-solid";
    DSIconsSolidConfig["ListUiAltSolid"] = "list-ui-alt-solid";
    DSIconsSolidConfig["ListUlSolid"] = "list-ul-solid";
    DSIconsSolidConfig["LockAccessSolid"] = "lock-access-solid";
    DSIconsSolidConfig["LockAltSolid"] = "lock-alt-solid";
    DSIconsSolidConfig["LockOpenAltSolid"] = "lock-open-alt-solid";
    DSIconsSolidConfig["LockSolid"] = "lock-solid";
    DSIconsSolidConfig["MapMarkerSolid"] = "map-marker-solid";
    DSIconsSolidConfig["MicroscopeSolid"] = "microscope-solid";
    DSIconsSolidConfig["MinusSquareFullSolid"] = "minus-square-full-solid";
    DSIconsSolidConfig["MultiplySolid"] = "multiply-solid";
    DSIconsSolidConfig["ObjectGroupSolid"] = "object-group-solid";
    DSIconsSolidConfig["ObjectUngroupSolid"] = "object-ungroup-solid";
    DSIconsSolidConfig["PadlockSolid"] = "padlock-solid";
    DSIconsSolidConfig["PaperclipSolid"] = "paperclip-solid";
    DSIconsSolidConfig["ParagraphSolid"] = "paragraph-solid";
    DSIconsSolidConfig["PauseSolid"] = "pause-solid";
    DSIconsSolidConfig["PentagonSolid"] = "pentagon-solid";
    DSIconsSolidConfig["PetzFavoriteSolid"] = "petz-favorite-solid";
    DSIconsSolidConfig["PolygonSolid"] = "polygon-solid";
    DSIconsSolidConfig["PreviousSolid"] = "previous-solid";
    DSIconsSolidConfig["ProcessSolid"] = "process-solid";
    DSIconsSolidConfig["RecordAudioSolid"] = "record-audio-solid";
    DSIconsSolidConfig["RedoSolid"] = "redo-solid";
    DSIconsSolidConfig["RefreshSolid"] = "refresh-solid";
    DSIconsSolidConfig["RepeatSolid"] = "repeat-solid";
    DSIconsSolidConfig["RightIndentAltSolid"] = "right-indent-alt-solid";
    DSIconsSolidConfig["RightIndentSolid"] = "right-indent-solid";
    DSIconsSolidConfig["RocketSolid"] = "rocket-solid";
    DSIconsSolidConfig["RulerCombinedSolid"] = "ruler-combined-solid";
    DSIconsSolidConfig["RulerSolid"] = "ruler-solid";
    DSIconsSolidConfig["SanitizerAltSolid"] = "sanitizer-alt-solid";
    DSIconsSolidConfig["SanitizerSolid"] = "sanitizer-solid";
    DSIconsSolidConfig["ScenerySolid"] = "scenery-solid";
    DSIconsSolidConfig["ScheduleSolid"] = "schedule-solid";
    DSIconsSolidConfig["ShieldPlusSolid"] = "shield-plus-solid";
    DSIconsSolidConfig["SideMenuColor"] = "side-menu-color";
    DSIconsSolidConfig["SignalAlt3Solid"] = "signal-alt-3-solid";
    DSIconsSolidConfig["SignalAltSolid"] = "signal-alt-solid";
    DSIconsSolidConfig["SignoutSolid"] = "signout-solid";
    DSIconsSolidConfig["SocialDistancingSolid"] = "social-distancing-solid";
    DSIconsSolidConfig["SortingSolid"] = "sorting-solid";
    DSIconsSolidConfig["SpaceKeySolid"] = "space-key-solid";
    DSIconsSolidConfig["SquareFullSolid"] = "square-full-solid";
    DSIconsSolidConfig["StarHalfAltSolid"] = "star-half-alt-solid";
    DSIconsSolidConfig["StarSolid"] = "star-solid";
    DSIconsSolidConfig["StepForwardSolid"] = "step-forward-solid";
    DSIconsSolidConfig["StethoscopeAltSolid"] = "stethoscope-alt-solid";
    DSIconsSolidConfig["StethoscopeSolid"] = "stethoscope-solid";
    DSIconsSolidConfig["StopwatchSolid"] = "stopwatch-solid";
    DSIconsSolidConfig["StoreSlashSolid"] = "store-slash-solid";
    DSIconsSolidConfig["SubjectSolid"] = "subject-solid";
    DSIconsSolidConfig["SyncExclamationSolid"] = "sync-exclamation-solid";
    DSIconsSolidConfig["SyncSlashSolid"] = "sync-slash-solid";
    DSIconsSolidConfig["TableSolid"] = "table-solid";
    DSIconsSolidConfig["ThLargeSolid"] = "th-large-solid";
    DSIconsSolidConfig["TimesCircleSolid"] = "times-circle-solid";
    DSIconsSolidConfig["ToggleOffSolid"] = "toggle-off-solid";
    DSIconsSolidConfig["ToggleOnSolid"] = "toggle-on-solid";
    DSIconsSolidConfig["ToiletPaperSolid"] = "toilet-paper-solid";
    DSIconsSolidConfig["TriangleSolid"] = "triangle-solid";
    DSIconsSolidConfig["UnlockAltSolid"] = "unlock-alt-solid";
    DSIconsSolidConfig["UnlockSolid"] = "unlock-solid";
    DSIconsSolidConfig["UploadAltSolid"] = "upload-alt-solid";
    DSIconsSolidConfig["UserArrowsSolid"] = "user-arrows-solid";
    DSIconsSolidConfig["UserMdSolid"] = "user-md-solid";
    DSIconsSolidConfig["UserNurseSolid"] = "user-nurse-solid";
    DSIconsSolidConfig["VectorSquareAltSolid"] = "vector-square-alt-solid";
    DSIconsSolidConfig["VectorSquareSolid"] = "vector-square-solid";
    DSIconsSolidConfig["VirusSlashSolid"] = "virus-slash-solid";
    DSIconsSolidConfig["WebGridAltSolid"] = "web-grid-alt-solid";
    DSIconsSolidConfig["WebGridSolid"] = "web-grid-solid";
    DSIconsSolidConfig["WebSectionAltSolid"] = "web-section-alt-solid";
    DSIconsSolidConfig["WebSectionSolid"] = "web-section-solid";
    DSIconsSolidConfig["WindowGridSolid"] = "window-grid-solid";
    DSIconsSolidConfig["WindowMaximizeSolid"] = "window-maximize-solid";
    DSIconsSolidConfig["WindowSectionSolid"] = "window-section-solid";
    DSIconsSolidConfig["WrapTextSolid"] = "wrap-text-solid";
})(DSIconsSolidConfig = exports.DSIconsSolidConfig || (exports.DSIconsSolidConfig = {}));
;
var Name = [
    'airplay-solid',
    'align-alt-solid',
    'align-center-justify-solid',
    'align-center-solid',
    'align-justify-solid',
    'align-left-justify-solid',
    'align-left-solid',
    'align-letter-right-solid',
    'align-right-justify-solid',
    'align-right-solid',
    'analysis-solid',
    'analytics-solid',
    'anchor-solid',
    'angle-double-down-solid',
    'angle-double-left-solid',
    'angle-double-right-solid',
    'angle-double-up-solid',
    'angle-down-solid',
    'angle-left-solid',
    'angle-right-b-solid',
    'angle-right-solid',
    'angle-up-solid',
    'apps-solid',
    'arrow-circle-down-solid',
    'arrow-circle-left-solid',
    'arrow-circle-right-solid',
    'arrow-circle-up-solid',
    'arrow-down-left-solid',
    'arrow-down-right-solid',
    'arrow-up-left-solid',
    'arrow-up-right-solid',
    'at-solid',
    'bag-solid',
    'bars-solid',
    'battery-bolt-solid',
    'battery-empty-solid',
    'bolt-slash-solid',
    'bolt-solid',
    'bookmark-solid',
    'border-alt-solid',
    'border-bottom-solid',
    'border-clear-solid',
    'border-horizontal-solid',
    'border-inner-solid',
    'border-left-solid',
    'border-out-solid',
    'border-right-solid',
    'border-top-solid',
    'border-vertical-solid',
    'briefcase-solid',
    'calendar-solid',
    'chart-pie-solid',
    'chart-solid',
    'check-circle-solid',
    'check-solid',
    'check-square-solid',
    'circle-layer-solid',
    'clinic-medical-solid',
    'clock-eight-solid',
    'clock-five-solid',
    'clock-nine-solid',
    'clock-seven-solid',
    'clock-solid',
    'clock-ten-solid',
    'clock-three-solid',
    'clock-two-solid',
    'columns-solid',
    'comment-dots-solid',
    'compress-solid',
    'corner-down-left-solid',
    'corner-down-right-solid',
    'corner-left-down-solid',
    'corner-right-down-solid',
    'corner-up-left-solid',
    'corner-up-right-solid',
    'coronavirus-solid',
    'dialpad-solid',
    'direction-solid',
    'dislike-solid',
    'document-layout-center-solid',
    'document-layout-left-solid',
    'document-layout-right-solid',
    'download-alt-solid',
    'ellipsis-h-solid',
    'ellipsis-v-solid',
    'exclamation-circle-solid',
    'exclamation-octagon-solid',
    'exclamation-triangle-solid',
    'favorite-solid',
    'flip-h-alt-solid',
    'flip-h-solid',
    'flip-v-alt-solid',
    'flip-v-solid',
    'graph-bar-solid',
    'grid-solid',
    'grids-solid',
    'grip-horizontal-line-solid',
    'head-side-cough-solid',
    'head-side-mask-solid',
    'head-side-solid',
    'history-alt-solid',
    'history-solid',
    'horizontal-align-left-solid',
    'hospital-solid',
    'hospital-square-sign-solid',
    'hospital-symbol-solid',
    'house-user-solid',
    'image-v-solid',
    'key-skeleton-alt-solid',
    'key-skeleton-solid',
    'keyhole-circle-solid',
    'keyhole-square-full-solid',
    'keyhole-square-solid',
    'layer-group-solid',
    'layers-alt-solid',
    'left-indent-alt-solid',
    'left-indent-solid',
    'like-solid',
    'line-spacing-solid',
    'link-h-solid',
    'list-ui-alt-solid',
    'list-ul-solid',
    'lock-access-solid',
    'lock-alt-solid',
    'lock-open-alt-solid',
    'lock-solid',
    'map-marker-solid',
    'microscope-solid',
    'minus-square-full-solid',
    'multiply-solid',
    'object-group-solid',
    'object-ungroup-solid',
    'padlock-solid',
    'paperclip-solid',
    'paragraph-solid',
    'pause-solid',
    'pentagon-solid',
    'petz-favorite-solid',
    'polygon-solid',
    'previous-solid',
    'process-solid',
    'record-audio-solid',
    'redo-solid',
    'refresh-solid',
    'repeat-solid',
    'right-indent-alt-solid',
    'right-indent-solid',
    'rocket-solid',
    'ruler-combined-solid',
    'ruler-solid',
    'sanitizer-alt-solid',
    'sanitizer-solid',
    'scenery-solid',
    'schedule-solid',
    'shield-plus-solid',
    'side-menu-color',
    'signal-alt-3-solid',
    'signal-alt-solid',
    'signout-solid',
    'social-distancing-solid',
    'sorting-solid',
    'space-key-solid',
    'square-full-solid',
    'star-half-alt-solid',
    'star-solid',
    'step-forward-solid',
    'stethoscope-alt-solid',
    'stethoscope-solid',
    'stopwatch-solid',
    'store-slash-solid',
    'subject-solid',
    'sync-exclamation-solid',
    'sync-slash-solid',
    'table-solid',
    'th-large-solid',
    'times-circle-solid',
    'toggle-off-solid',
    'toggle-on-solid',
    'toilet-paper-solid',
    'triangle-solid',
    'unlock-alt-solid',
    'unlock-solid',
    'upload-alt-solid',
    'user-arrows-solid',
    'user-md-solid',
    'user-nurse-solid',
    'vector-square-alt-solid',
    'vector-square-solid',
    'virus-slash-solid',
    'web-grid-alt-solid',
    'web-grid-solid',
    'web-section-alt-solid',
    'web-section-solid',
    'window-grid-solid',
    'window-maximize-solid',
    'window-section-solid',
    'wrap-text-solid',
];
var DSIconsSolid = { Unicode: Unicode, Name: Name };
exports["default"] = DSIconsSolid;
