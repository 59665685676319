import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum AutoComplete {
  On = 'on',
  Off = 'off',
}

enum IconAlign {
  Left = 'left',
  Right = 'right',
}

enum Kind {
  Boxed = 'boxed',
  Lined = 'lined',
}

enum RequirementLabel {
  Required = 'obrigatório',
  Optional = 'opcional',
}

enum Size {
  Sm = 'sm',
  Mid = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

enum State {
  Disabled = 'disabled',
  Resting = 'resting',
  Error = 'error',
  AutoFilled = 'auto-filled',
  Loading = 'loading',
}

export const PTZInputConfigList = {
  AutoComplete: EnumToArray([AutoComplete]),
  Brand: PTZBrandConfigList.Name,
  IconAlign: EnumToArray([IconAlign]),
  Kind: EnumToArray([Kind]),
  RequirementLabel: EnumToArray([RequirementLabel]),
  Size: EnumToArray([Size]),
  State: EnumToArray([State]),
};

export const PTZInputConfig = {
  AutoComplete,
  Brand: PTZBrandConfig.Name,
  IconAlign,
  Kind,
  RequirementLabel,
  Size,
  State,
};
